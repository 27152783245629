import React from 'react';
import { withStyles, TableContainer, TableHead, TableRow, TableCell, Table, TableBody, Typography} from '@material-ui/core';
import BinsItemsDetailsCardStyle from './BinsItemsDetailsCardStyle';
import Paper from '@material-ui/core/Paper';

const BinsItemsDetailsCard = ({ binItems, classes }) => {

    if(binItems.length > 0) {
        return (
          <TableContainer component={Paper}>
              <Table>
                  <TableHead>
                      <TableRow className={classes.tableRowHeader}>
                          <TableCell align="center" className={classes.tableHeaderCell}>Bin/Item ID</TableCell>
                          <TableCell align="center" className={classes.tableHeaderCell}>Insurance</TableCell>
                          <TableCell align="center" className={classes.tableHeaderCell}>Details</TableCell>
                          <TableCell align="center" className={classes.tableHeaderCell}>Status</TableCell>
                      </TableRow>
                  </TableHead>
                  <TableBody>
                      {binItems.map(({ binId, insuranceAmount, binType, status }, index) => (
                        <TableRow key={binId}>
                            <TableCell align="center" className={classes.tableBodyCell}>{binId}</TableCell>
                            <TableCell align="center" className={classes.tableBodyCell}>${insuranceAmount}</TableCell>
                            <TableCell align="center" className={classes.tableBodyCell}>{binType}</TableCell>
                            <TableCell align="center" className={classes.tableBodyCell}>{status}</TableCell>
                        </TableRow>
                      ))}
                  </TableBody>
              </Table>
          </TableContainer>
        )
    }
    else {
        return (
          <div className={classes.noBinsItems}>
              <Typography variant={'h5'}>
                  No Bins/Items
              </Typography>
          </div>
        )
    }
};

export default withStyles(BinsItemsDetailsCardStyle)(BinsItemsDetailsCard);
