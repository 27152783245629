import React, { useContext, useState, useEffect } from 'react';
import { Grid, Typography, withStyles } from '@material-ui/core';
import CurrentOrderPane from "components/MyAccount/CurrentOrderPane/CurrentOrderPane";
import OrdersCardStyle from './OrdersCardStyle';
import { UserContext } from "context/user";
import { chunkArray } from 'components/Common/Util/util';
import BinsItemsDetailsCard from "components/MyAccount/BinsItemsDetailsCard/BinsItemsDetailsCard";
import PaginationChevronCountPanel from 'components/Common/PaginationChevronCountPanel/PaginationChevronCountPanel';

const OrdersCard = (props) => {
  const { classes, isAdminView=false, userData: adminUserData } = props;
  const [activePage, setActivePage] = useState(0);
  // use context userData as default, override with propUserData if isAdminView is true
  const contextUserData = useContext(UserContext).userData;
  const userData = isAdminView ? adminUserData : contextUserData;
  const [binItems, setBinItems] = useState([]);
  const [currentIndex, setCurrentIndex] = useState(0);

  useEffect(() => {
    let newBinItems = [];
    if(userData && userData.orders) {
      for(let order of userData.orders) {
        for(let bin of order.bins) {
          newBinItems.push({
            binId: bin.binId,
            insuranceAmount: bin.insuranceAmount || '100',
            binType: bin.binType,
            status: bin.readableStatus
          });
        }
        for(let item of order.items) {
          newBinItems.push({
            binId: item.itemId,
            insuranceAmount: item.insuranceAmount || '100',
            binType: item.itemType,
            status: item.readableStatus
          });
        }
      }

      newBinItems = chunkArray(newBinItems, 20);
      setBinItems(newBinItems);
    }
  }, [userData]);

  const updatePageIndex = (paginationType) => {
    if(paginationType) {
      if(currentIndex !== binItems.length - 1) {
        setCurrentIndex(currentIndex + 1);
      }
    }
    else {
      if(currentIndex > 0 && currentIndex < binItems.length) {
        setCurrentIndex(currentIndex - 1);
      }
    }
  };

  return (
    <>
      <Grid container className={classes.margin}>
        <Grid container alignItems="center" className={classes.headerContainer}>
          <Grid
            item
            className={activePage === 0 ? classes.enabledButton : classes.disabledButton}
            onClick={() => setActivePage(0)}
          >
            <Typography variant="h5">MY ORDERS</Typography>
          </Grid>
          <Grid
            item
            className={activePage === 1 ? classes.enabledButton : classes.disabledButton}
            onClick={() => setActivePage(1)}
          >
            <Typography variant="h5">BINS/ITEMS</Typography>
          </Grid>
        </Grid>

        <Grid container className={classes.body}>
          {activePage === 0 ? (
            <CurrentOrderPane isAdminView={isAdminView} adminUserData={userData} />
          ) : (
            <>
              <BinsItemsDetailsCard binItems={binItems[currentIndex] || []} />
              <div className={classes.paginationContainer}>
                <PaginationChevronCountPanel
                  pageIndex={currentIndex+1}
                  pageCount={binItems.length || 1}
                  updatePageIndex={updatePageIndex}
                />
              </div>
            </>
          )}

          {/* Conditional rendering based on userData existence */}
          {userData && (
            <Typography className={classes.currentTotal} variant={'h5'}>
              Updated monthly total: ${parseFloat(userData.currentMonthlyBilledAmount/100).toFixed(2)}
            </Typography>
          )}
        </Grid>
      </Grid>
    </>
  );
};

export default withStyles(OrdersCardStyle)(OrdersCard);
