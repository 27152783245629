import React, { useState } from 'react';
import {
    Card,
    CardContent,
    Grid,
    Typography,
    Checkbox,
    withStyles,
    Modal,
    Backdrop,
    Fade
} from '@material-ui/core';
import StorageCardStyle from './StorageCardStyle';
import noImageDefaultImg from 'assets/img/my_account/default-my-account-img.png';

const StorageCard = (props) => {
    const { classes, updateClickedBins, bin, orderId, isAdminView } = props;
    const [isBinSelected, setIsBinSelected] = useState(props.selected);
    const [openModal, setOpenModal] = useState(false);

    const updateBinState = (binId, isBinSelected) => {
        setIsBinSelected(isBinSelected);
        updateClickedBins(binId, isBinSelected);
    };

    const getStatus = (statusCode) => {
        // Statuses remain unchanged
    };

    const zoomImage = () => {
        if (isAdminView) {
            setOpenModal(true);
        }
    };

    const handleCloseModal = () => {
        setOpenModal(false);
    };

    return (
      <>
          <Card
            className={isBinSelected ? classes.highlightedCard : classes.card}
            onClick={() => isAdminView ? zoomImage() : updateBinState(bin, !isBinSelected)}
          >
              <div className={classes.cardImage}>
                  <img src={bin.images.length > 0 ? bin.images?.[0]?.image : noImageDefaultImg} alt="bin" />
              </div>
              <CardContent className={classes.cardContent}>
                  <Checkbox
                    className={classes.checkbox}
                    color={'secondary'}
                    checked={isBinSelected}
                    onChange={() => isAdminView ? () => {} : updateBinState(bin, !isBinSelected)}
                    size={'medium'}
                  />
                  {/* Content remains unchanged */}
              </CardContent>
          </Card>
          <Modal
            aria-labelledby="transition-modal-title"
            aria-describedby="transition-modal-description"
            className={classes.modal}
            open={openModal}
            onClose={handleCloseModal}
            closeAfterTransition
            BackdropComponent={Backdrop}
            BackdropProps={{
                timeout: 500,
            }}
          >
              <Fade in={openModal}>
                  <div className={classes.paper}>
                      <img src={bin.images.length > 0 ? bin.images?.[0]?.image : noImageDefaultImg} alt="Zoomed Bin" className={classes.zoomedImage} />
                  </div>
              </Fade>
          </Modal>
      </>
    );
};

export default withStyles(StorageCardStyle)(StorageCard);
