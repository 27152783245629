import React, { createContext, useEffect, useLayoutEffect, useState } from 'react';
import Cookies from 'universal-cookie';
import djangoService from 'services/djangoService';
import {useHistory, useLocation} from 'react-router-dom';

export const UserContext = createContext();

const UserProvider = ({children}) => {
  const [userData, setUserData] = useState(null);
  const [selectedBins, setSelectedBins] = useState([]);
  const [selectedItems, setSelectedItems] = useState([]);
  const history = useHistory();
  const location = useLocation();

  useEffect(() => {
    updateFromApi(false);
  }, []);

  const updateFromApi = (redirect=false) => {
    djangoService.User.get().then(response => {
      console.log('response', response)
      console.log('set user data', response.data)
      setAndSaveToCookie(response.data);
    }).catch(error => {
      console.log('error', error);
      if(error.response && error.response.status === 403) {
        console.log('redirect to login', location)

        // check if the location starts with /admin-my-account

        if(!location.pathname.startsWith('/admin-my-account') && redirect) {
          console.log('redirect to login', location)
          history.replace('/login')
        }
      }
    })
  }

  const setAndSaveToCookie = (userData) => {
    setUserData(userData);
    console.log('user data set', userData)

    if(userData) {
      console.log('save to cookie', userData);
      const cookies = new Cookies();

      cookies.set('user', userData, {
        path: '/'
      });
    }
  }

  const checkForUserData = async () => {
    console.log('check for user data')
    const cookies = new Cookies();

    // these can return "undefined" as a string
    const cookieUser = cookies.get('user');
    const cookieToken = cookies.get('token');

    if(cookieUser && cookieUser !== "undefined") {
      console.log('use saved user data', cookieUser)
      // use the raw function because we're pulling from the cookie, so we don't need to save again
      setUserData(cookieUser);

      // if(redirectOnSuccess)
      //   history.replace('/my-account')
    }
    else if(cookieToken) {
      console.log('get user data')
      updateFromApi(true);

      // if(redirectOnSuccess)
      //   history.replace('/my-account')
    }
    else {
      console.log('redirect to login', location)
      history.replace('/login')
    }
  }

  const values = {
    userData,
    // overwrite this to also save to cookie whenever data changes
    setUserData: setAndSaveToCookie,
    setUserDataRaw: setUserData,
    checkForUserData,
    updateFromApi,
    selectedBins,
    setSelectedBins,
    selectedItems,
    setSelectedItems,
  }

  return (
    <UserContext.Provider value={values}>
      {children}
    </UserContext.Provider>
  )
};

export default UserProvider
