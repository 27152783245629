import React, {Suspense, useEffect} from 'react';
import ReactDOM from 'react-dom';
import {Redirect, Route, BrowserRouter as Router, Switch, useLocation} from 'react-router-dom';
import { createMuiTheme, MuiThemeProvider, responsiveFontSizes } from '@material-ui/core';
import { MuiTheme } from 'assets/theme';
import ReactGA from 'react-ga';
import smartlookClient from 'smartlook-client';

// Components
import Logout from './components/Layout/AuthPanes/Logout/Logout';

//Context
import UserProvider from "context/user";
import OrderProvider from './context/order';
import SimpleReactLightbox from "simple-react-lightbox";

import moment from 'moment-timezone';
import * as Sentry from '@sentry/react';
import { DEBUG } from 'settings';
import Loading from "components/Common/Loading/Loading";
import NavbarWrapper from "components/Routing/NavbarWrapper/NavbarWrapper";
import ProtectedRoute from "components/Routing/ProtectedRoute/ProtectedRoute";
import AdminMyAccountPage from "pages/AdminMyAccountPage/AdminMyAccountPage";

const LoginPage = React.lazy(() =>
  import('./pages/AuthPages/LoginPage/LoginPage'));

const RegistrationPage = React.lazy(() =>
  import('./pages/AuthPages/RegistrationPage/RegistrationPage'));

const MyAccountPage = React.lazy(() =>
  import('./pages/MyAccountPage/MyAccountPage'));

const OrderPage = React.lazy(() =>
  import('./pages/OrderPages/OrderPage/OrderPage'));

const HomePage = React.lazy(() =>
  import('./pages/HomePage/HomePage'));

const FaqPage = React.lazy(() =>
  import('./pages/FaqPage/FaqPage'));

const AboutUsPage = React.lazy(() =>
  import('./pages/AboutUsPage/AboutUsPage'));

const DocumentPage = React.lazy(() =>
  import('./pages/DocumentPage/DocumentPage'));

const ConfirmPasswordResetPage = React.lazy(() =>
  import('./pages/ConfirmPasswordResetPage/ConfirmPasswordResetPage'));

const RequestPasswordResetPage = React.lazy(() =>
  import('./pages/RequestPasswordResetPage/RequestPasswordResetPage'));

let theme = createMuiTheme(MuiTheme);
theme = responsiveFontSizes(theme);
moment.tz.setDefault('America/Phoenix');
ReactGA.initialize('UA-169010638-1');
smartlookClient.init('529e02feeb58fc19a87f42ab3ef2dcaae7b167be');

if(!DEBUG) {
  // Sentry.init({
  //   dsn: "https://f9e3c3806da441c38a5a4f9c22ad91c0@o360663.ingest.sentry.io/5340743",
  // });
}

const App = () => {
  const location = useLocation();

  useEffect(() => {
    ReactGA.pageview(location.pathname + location.search);
    smartlookClient.navigation(location.pathname + location.search);
  }, [location]);

  return (
    <UserProvider>
      <Switch>
        <Route exact path={'/login'}>
          <LoginPage/>
        </Route>

        <Route exact path={'/logout'} component={Logout}/>

        <Route exact path={'/registration'}>
          <RegistrationPage/>
        </Route>

        <Route exact path={'/forgot-password'}>
          <NavbarWrapper>
            <RequestPasswordResetPage/>
          </NavbarWrapper>
        </Route>

        <Route exact path={'/password-reset'}>
          <NavbarWrapper>
            <ConfirmPasswordResetPage/>
          </NavbarWrapper>
        </Route>

        <Route exact path={'/'}>
          <NavbarWrapper>
            <HomePage/>
          </NavbarWrapper>
        </Route>

        <Route exact path={'/faq'}>
          <NavbarWrapper>
            <FaqPage/>
          </NavbarWrapper>
        </Route>

        <Route exact path={'/about'}>
          <NavbarWrapper>
            <AboutUsPage/>
          </NavbarWrapper>
        </Route>

        <Route exact path={'/privacy-policy'}>
          <NavbarWrapper>
            <DocumentPage fileName={'privacy_policy'}/>
          </NavbarWrapper>
        </Route>

        <Route exact path={'/terms-and-conditions'}>
          <NavbarWrapper>
            <DocumentPage fileName={'terms_and_conditions'}/>
          </NavbarWrapper>
        </Route>

        <Route exact path={'/order/storage'}>
          <OrderProvider>
            <NavbarWrapper>
              <OrderPage/>
            </NavbarWrapper>
          </OrderProvider>
        </Route>

        <Route exact path={'/admin-my-account'}>
          <OrderProvider>
            <NavbarWrapper>
              <AdminMyAccountPage/>
            </NavbarWrapper>
          </OrderProvider>
        </Route>

        <ProtectedRoute exact path={'/my-account'}>
          <OrderProvider>
            <NavbarWrapper>
              <MyAccountPage/>
            </NavbarWrapper>
          </OrderProvider>
        </ProtectedRoute>

        <Redirect to="/" />
      </Switch>
    </UserProvider>
  )
}

ReactDOM.render(
  <SimpleReactLightbox>
    <MuiThemeProvider theme={theme}>
      <Suspense fallback={<Loading show/>}>
        <Router>
          <App/>
        </Router>
      </Suspense>
    </MuiThemeProvider>
  </SimpleReactLightbox>,
  document.getElementById('root')
);
