import { breakpoints, MuiTheme } from '../../../assets/theme';

const FooterStyle = {
  root: {
    width: '100%',
    background: 'black',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'flex-start',
    alignItems: 'stretch',
    paddingBottom: '20px',

    '& *': {
      color: MuiTheme.palette.secondary.main
    }
  },
  comingSoon: {
    marginTop: '20px'
  },

  content: {
    width: '100%',
    boxSizing: 'border-box',
    margin: '0 auto',
    display: 'grid',
    gridTemplateColumns: '25% 25% 25% 25%',
    padding: '40px',
    paddingRight: '20px',
    paddingLeft: '110px'
  },
  smallText: {
    fontSize: '16px',
  },
  smallTextParagraph: {
    fontSize: '16px',
    width: '90%',
    marginRight: 'auto'
  },
  linkText: {
    fontSize: '16px',
    cursor: 'pointer',
    transition: '0.3s',

    '&:hover': {
      textDecoration: 'underline'
    }
  },
  smallerText: {
    fontSize: '14px'
  },
  logo: {
    width: '160px',
    height: '60px',
    marginTop: '0px'
  },
  socialContainerWrapper: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',

    '& > *': {
      margin: '0 4px'
    }
  },
  followUs: {
    fontSize: '23px',
    fontWeight: 600
  },
  socialContainer: {
    height: '34px',
    width: '34px',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    backgroundColor: MuiTheme.palette.secondary.main,
    borderRadius: '8px',

    '& *': {
      color: 'black !important'
    }
  },
  socialContainerImg: {
    width: '30px',
    height: '30px',
    backgroundSize: 'cover'
  },
  appStoreButtonContainer: {
    width: '100%',
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'flex-start',
    alignItems: 'center'
  },
  appStoreButton: {
    width: '160px',
    height: '60px',
  },
  serviceAreaImg: {
    width: '170px',
    height: '100px'
  },
  serviceAreaOuterContainer: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'flex-start',
    alignItems: 'center'
  },
  serviceAreaInnerContainer: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    paddingRight: '20%',
    boxSizing: 'border-box',
    alignItems: 'center',
    width: '100%',
  },
  linkElem: {
    color: 'inherit',
    textDecoration: 'none',

    '&:hover': {
      textDecoration: 'underline'
    }
  },

  col1: {
    '& > *': {
      margin: '8px 0'
    },
  },
  col2: {
    '& > *': {
      margin: '15px 0'
    }
  },
  col3: {
    '& > *': {
      margin: '20px 0'
    },
    '& > *:first-of-type': {
      marginTop: 0
    }
  },
  col4: {
    paddingLeft: '20px',
    '& > *': {
      display: 'block',
      margin: '20px 0'
    },
    '& > *:first-of-type': {
      marginTop: 0
    }
  },
  bottomText: {
    width: '90%',
    marginLeft: 'auto',
    marginRight: 'auto'
  },

  [breakpoints.medium]: {
    content: {
      gridTemplateColumns: '50% 50%',
      padding: '40px 50px'
    },
    col3: {
      marginTop: '15px'
    },
    col4: {
      paddingLeft: '0',
      marginTop: '15px'
    }
  },

  [breakpoints.small]: {
    content: {
      gridTemplateColumns: '100%',
      padding: '40px 25px',
    },
    col: {
      marginTop: '25px',
      maxWidth: '400px',

      '&:first-of-type': {
        marginTop: '0px'
      }
    }
  },
};

export default FooterStyle;
