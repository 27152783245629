import { MuiTheme, button, breakpoints } from 'assets/theme';

const MyBinsCardStyle = {
  mainHeader: {
    minWidth: 280,
    height: '70px',
    backgroundColor: MuiTheme.palette.secondary.main,
    display: 'flex',
    justifyContent: 'flex-start',
    padding: '0 35px',
    alignItems: 'center'
  },
  body: {
    border: '1px solid grey',
    padding: '0 48px',
    paddingTop: '24px',
    backgroundColor: MuiTheme.palette.common.white
  },
  selectAllContainer: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'flex-start',
    alignItems: 'center',
    marginBottom: '20px',

    '& .MuiFormControlLabel-label': {
      marginTop: '2px'
    }
  },
  focusedButton: {
    ...button,
    borderRadius: '0',
    '&:hover': {
      background: MuiTheme.palette.common.black
    },
    background: MuiTheme.palette.common.black,
    color: MuiTheme.palette.secondary.main,
    maxWidth: '250px'
  },
  unfocusedButton: {
    ...button,
    borderRadius: '0',
    '&:hover': {
      background: MuiTheme.palette.secondary.main
    },
    background: MuiTheme.palette.secondary.main,
    color: MuiTheme.palette.common.black,
    maxWidth: '250px'
  },
  margin: {
    marginTop: '5%'
  },
  paginationPanel: {
    marginBottom: '3%',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center'
  },
  noBins: {
    width: '100%',
    marginTop: '15px',
    marginBottom: '45px',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center'
  },
  photoInfoText: {
    marginBottom: '60px'
  },

  [breakpoints.small]: {
    mainHeader: {
      width: '65%',
    }
  }
}

export default MyBinsCardStyle
