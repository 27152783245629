import camelcaseKeys from 'camelcase-keys';

export const deepObjValueToString = (o) => {
  Object.keys(o).forEach(k => {
    if (typeof o[k] === 'object' && !Array.isArray(o[k])) {
      return deepObjValueToString(o[k]);
    }
    o[k] = '' + o[k];
  });
  return o;
};

export const formatDjangoApiErrorsToStrings = (snakeCaseErrorObj) => {
  /**
   * Convert a SNAKECASE Django API error object to strings with CAMELCASE keys
   */
  let deepStrObj = deepObjValueToString(snakeCaseErrorObj);
  return camelcaseKeys(deepStrObj, { deep: true })
};

export const isFunction = (functionToCheck) => {
  return functionToCheck && {}.toString.call(functionToCheck) === '[object Function]';
};
