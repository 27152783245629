import React, { useState, useContext } from 'react';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import SnackbarAlert from 'components/Common/Alert/Snackbar';
import { UserContext } from "context/user";
import djangoService from "services/djangoService";

const CancelAlert = ({ isCancelAlert, setIsCancelAlert, orderId }) => {

  const [showAlert, setShowAlert] = useState({ open: false, severity: '', message: '' });
  const { userData, setUserData } = useContext(UserContext);

  const handleClose = () => {
    setIsCancelAlert(false);
  };

  const cancelOrder = () => {
    djangoService.Order.cancel(orderId).then(response => {
      setShowAlert({ open: true, severity: 'success', message: 'Cancelled order successfully' });
      setIsCancelAlert(false);
      setUserData({ ...userData, orders: response.data.orders })
    }).catch(error => {
      console.log(error)
      setShowAlert({ open: true, severity: 'error', message: 'Error occurred when cancelling order' });
      setIsCancelAlert(false);
    })
  };

  return (
    <>
      <Dialog
        open={isCancelAlert}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">{'Do you want to cancel the order?'}</DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose} color="primary">
            No
          </Button>
          <Button onClick={cancelOrder} color="primary" autoFocus>
            Yes
          </Button>
        </DialogActions>
      </Dialog>
      <SnackbarAlert showAlert={showAlert} setShowAlert={setShowAlert} />
    </>
  )
}

export default CancelAlert;
