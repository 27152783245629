import React from 'react';
import NavBar from "components/Layout/NavBar/NavBar";
import Footer from "components/Layout/Footer/Footer";

const NavbarWrapper = ({children}) => {

  return (
    <>
      <NavBar/>
        {children}
      <Footer/>
    </>
  );
};

export default NavbarWrapper;
