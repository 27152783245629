const ORDER_STATUSES = {
  WAITING_FOR_DROPOFF: 0,
  RENTAL_WITH_CUSTOMER: 1,
  WAITING_FOR_PICKUP: 2,
  IN_TRANSIT_TO_WAREHOUSE_FOR_STORAGE: 3,
  IN_TRANSIT_TO_CUSTOMER: 4,
  STORED_IN_WAREHOUSE: 5,
  INVALID_STATUS: 6,
  PICKUP_SCHEDULED: 7,
  DROPOFF_SCHEDULED: 8,
  PARTIALLY_IN_STORAGE: 9,
  PARTIALLY_RENTED: 10,
  FULFILLED: 11,
  CANCELLED: 12
};

export const FINISHED_ORDER_STATUSES = [ORDER_STATUSES.FULFILLED, ORDER_STATUSES.CANCELLED];

export default ORDER_STATUSES;
