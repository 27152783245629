import React, {useState, useContext, useEffect} from 'react';
import Modal from '@material-ui/core/Modal';
import Backdrop from '@material-ui/core/Backdrop';
import {Button, Fade, Typography, withStyles} from '@material-ui/core';
import Paper from '@material-ui/core/Paper';
import MenuItem from '@material-ui/core/MenuItem';
import { UserContext } from "context/user";
import { updateBinItemStatus } from 'components/Common/Util/util';
import { formatMyInfoAddress } from 'components/Common/Util/util';
import {Form, Formik} from "formik";
import DatePickerField from "components/Common/Form/DatePickerField";
import TextField from "@material-ui/core/TextField";
import djangoService from "services/djangoService";
import {handleFormErrors} from "services/errorHandlerService";
import PickUpModalStyle from "components/MyAccount/PickUpModal/PickUpModalStyle";
import moment from "moment-timezone";
import * as Yup from "yup";
import {Autocomplete} from "@material-ui/lab";

const PickUpModal = (props) => {
  const {
    classes,

    // used only for full pickups
    data,
    fullPickup=false,
    useProvidedAddress=false,
    orderPlacedPickup=false,
    orderId,
  } = props;
  const [availableTimes, setAvailableTimes] = useState(null);
  const [loading, setLoading] = useState(false);
  const [outerError, setOuterError] = useState('');
  const [userOrderOptions, setUserOrderOptions] = useState([]);

  const { userData, setUserData, selectedBins, selectedItems } = useContext(UserContext);

  useEffect(() => {
    console.log('full pickup data', data);
  }, [data])

  useEffect(() => {
    if(userData && userData.orders) {
      let options = [];
      for(let i of userData.orders) {
        options.push({label: i.uuid, value: i.id});
      }

      setUserOrderOptions(options);
    }
  }, [userData])

  console.log('selected bins', selectedBins)
  console.log('selected items', selectedItems)

  useEffect(() => {
    console.log('full pickup data', data);
  }, [data])

  const validationSchema = Yup.object().shape({
    timeScheduled: Yup.string().required('Time is required'),
    dateScheduled: Yup.string().required('Date is required').nullable(),
  })

  const handleClose = () => {
    props.setOpen(false);

    if(outerError) {
      setOuterError('');
    }

    if(data?.onFinish && orderPlacedPickup) {
      data.onFinish(null);
    }
  };

  const getAvailableTimes = (date, minTime) => {
    console.log('get available times', userData)
    console.log('franchise to send', data?.city?.toLowerCase())

    let userCity = useProvidedAddress ? data?.city?.toLowerCase() : userData?.city?.toLowerCase();

    djangoService.Order.getAvailableTimes(date, userCity).then(response => {
      console.log('available times', response.data);
      console.log('min time', minTime, moment(minTime, 'hh:mm:ss'));

      if(minTime) {
        let validTimes = [];
        for(let i of response.data) {
          let momentMinTime = moment(minTime, 'hh:mm:ss');

          if(moment(i.value, 'hh:mm:ss') > momentMinTime) {
            validTimes.push(i);
          }
        }

        console.log('valid times', {validTimes, original: response.data});
        setAvailableTimes(validTimes);
      }
      else {
        console.log('response.data', response.data);
        setAvailableTimes(response.data);
      }
    }).catch(error => {
      setOuterError('An error occurred getting available times, please try a different date or try again later')
    })
  };

  const onSubmit = async (values, {setErrors}) => {
    setLoading(true);
    // add bins and items to the values
    let body = {...values};
    body.bins = selectedBins;
    body.items = selectedItems;

    console.log('submit values', body);

    if(fullPickup) {
      if(data?.onFinish) {
        data.onFinish(body);
      }
    }
    else {
      djangoService.Pickup.create(body)
        .then(response => {
          console.log('response success', response);
          const newOrders = updateBinItemStatus(userData.orders, selectedBins, selectedItems, 8, 'A pickup has been scheduled');
          setUserData({ ...userData, orders: newOrders });
          setLoading(false);



          handleClose();
        })
        .catch(error => handleFormErrors(error, setErrors));
    }
  };

  return (
    <div>
      <Modal
        className={classes.modal}
        open={props.open}
        onClose={handleClose}
        closeAfterTransition
        BackdropComponent={Backdrop}
        BackdropProps={{
          timeout: 500,
        }}
      >
        <Fade in={props.open}>
          <div className={classes.paperContainer}>
            <Paper className={classes.paperStyle}>
              <Formik
                initialValues={{
                  dateScheduled: null,
                  timeScheduled: '',
                  orderSelected: orderPlacedPickup ? '' : orderId ? orderId : userData?.orders?.filter(order => order?.userReadableStatus !== 'Complete').length > 1
                    ? '' : userData?.orders?.filter(order => order.userReadableStatus !== 'Complete')?.[0]?.id,
                }}
                validationSchema={validationSchema}
                enableReinitialize
                validateOnChange={false}
                onSubmit={onSubmit}
              >
                {({
                    values,
                    errors,
                    handleChange,
                    setFieldValue
                  }) => (
                  <Form style={{position: 'relative'}}>
                    <div className={classes.closeButtonContainer} onClick={handleClose}>
                      X
                    </div>
                    <div className={classes.title}>SCHEDULE PICKUP</div>

                    {(!fullPickup) ?
                      (
                        <div className={classes.numberItems}>
                          {selectedBins.length + selectedItems.length} Bins/Items Selected
                        </div>
                      )
                      :
                      (
                        <div className={classes.fullPickupText}>
                          Already know when you want your pickup? You can place one here!{' '}
                          If not, close this dialog and you can schedule one later.
                        </div>
                      )
                    }

                    <div className={classes.addressText}>Your current delivery address is:</div>
                    <p className={classes.address}>
                      <strong>
                        {useProvidedAddress ? formatMyInfoAddress(data) : formatMyInfoAddress(userData)}
                      </strong>
                    </p>
                    <Typography variant={'body2'} paragraph>
                      <i>*You may update this address in My Info</i>
                    </Typography>
                    {(errors.nonFieldErrors || outerError) &&
                    <Typography variant={'body1'} color={'error'} align={'center'} paragraph>
                      {errors.nonFieldErrors || outerError}
                    </Typography>
                    }
                    <div className={classes.selectionContainer}>
                      {!orderId && !orderPlacedPickup && userData.orders.filter(order => order.userReadableStatus !== 'Complete').length > 1 && (
                        <div className={classes.formControl}>
                          <TextField
                            select
                            fullWidth
                            variant="outlined"
                            margin="normal"
                            name={'orderSelected'}
                            label={'Select Order'}
                            InputLabelProps={{shrink: true}}
                            error={!!errors.orderSelected}
                            onChange={handleChange}
                            helperText={errors.orderSelected}
                            value={values.orderSelected}
                          >
                            {userOrderOptions.map(({label, value}, index) => (
                              <MenuItem key={value} value={value}>
                                Order {value}
                              </MenuItem>
                            ))}
                          </TextField>
                        </div>
                      )}
                      <div className={classes.formControl}>
                        <DatePickerField
                          name="dateScheduled"
                          label="Pickup Date"
                          onChange={(isoDate, formattedDate) => {
                            getAvailableTimes(isoDate);

                            setFieldValue('dateScheduled', isoDate);
                          }}
                          fullWidth
                          variant="outlined"
                          margin="normal"
                          InputLabelProps={{shrink: true}}
                          value={values.dateScheduled}
                          error={!!errors.dateScheduled}
                          helperText={errors.dateScheduled}
                          minimumDate={fullPickup && moment(data?.pickup_date, 'YYYY-MM-DD')}
                          inputProps={{
                            min: 0,
                            style: { textAlign: 'left' }
                          }}
                          disabled={loading}
                        />
                      </div>
                      <div className={classes.formControl}>
                        <TextField
                          select
                          type="time"
                          name="timeScheduled"
                          label="Pickup Time"
                          fullWidth
                          variant="outlined"
                          margin="normal"
                          value={values.timeScheduled}
                          error={
                            !!availableTimes ?
                              availableTimes.length > 0 ? !!errors.timeScheduled : true
                              :
                              false
                          }
                          helperText={
                            !!availableTimes ?
                              availableTimes.length > 0 ? errors.timeScheduled : 'No times available for this date.'
                              :
                              ''
                          }
                          onChange={handleChange}
                          inputProps={{
                            min: 0,
                            style: { textAlign: 'left' }
                          }}
                          disabled={
                            availableTimes === null
                            ||
                            availableTimes.length === 0
                            ||
                            loading
                          }
                        >
                          {availableTimes ?
                            (availableTimes.map(({value, label}, index) => (
                              <MenuItem key={value} value={value}>
                                {label}
                              </MenuItem>
                            )))
                            :
                            (<MenuItem></MenuItem>)
                          }
                        </TextField>
                      </div>
                    </div>
                    <Button
                      className={classes.submitButton}
                      disabled={loading}
                      type={'submit'}
                    >
                      {'Submit'}
                    </Button>
                  </Form>
                )}
              </Formik>
            </Paper>
          </div>
        </Fade>
      </Modal>
    </div>
  );
};

export default withStyles(PickUpModalStyle)(PickUpModal);
