import React, { useContext, useEffect, useState } from 'react';
import { Button, Link, Grid, Typography, withStyles } from "@material-ui/core";

import AdminMyAccountPageStyle from "./AdminMyAccountPageStyle";
import MyBinsCard from "components/MyAccount/MyBinsCard/MyBinsCard";
import OrdersCard from "components/MyAccount/OrdersCard/OrdersCard";
import MyInfoCard from "components/MyAccount/MyInfoCard/MyInfoCard";
import NotificationCard from "components/MyAccount/NotificationCard/NotificationCard";
import { scrollByContainerId } from "components/Common/Util/util";

import BIN_ITEM_STATUSES from 'components/Common/Util/binItemStatusDefinitions';
import { UserContext } from "context/user";
import {useHistory} from "react-router-dom";
import djangoService from "services/djangoService";


const AdminMyAccountPage = (props) => {
  const { classes } = props;
  const history = useHistory();

  const [pickUpModalOpen, setPickUpModalOpen] = useState(false);
  const [dropOffModalOpen, setDropOffModalOpen] = useState(false);

  const [showPickupButton, setShowPickupButton] = useState(undefined);
  const [showDropoffButton, setShowDropoffButton] = useState(undefined);

  const {userData, setUserData, updateFromApi} = useContext(UserContext);

  const [fetchedUserData, setFetchedUserData] = useState(null);

  useEffect(() => {
    const searchParams = new URLSearchParams(window.location.search);
    const uuid = searchParams.get('u');

    if (uuid) {
      // Fetch user data based on the UUID
      fetchUserDataByUUID(uuid);
    }
  }, []);

  // NOTE: this is a fix for a weird bug with Links
  // https://stackoverflow.com/questions/45583358/open-link-at-top-of-page
  useEffect(() => {
    if (document.querySelector("#root")) {
      document.querySelector("#root").scrollTo(0, 0);
    }
  }, []);

  const fetchUserDataByUUID = (uuid) => {
    console.log('fetching user data by UUID', uuid)
    djangoService.User.getByUuid(uuid).then((response) => {
      console.log('User data by UUID', response)
      setFetchedUserData(response.data);
    }).catch(error => {
      console.log(error);
    });
  };

  return (
    <>
      <Grid container>
        <Grid
          container
          item
          lg={12}
          direction="column"
          alignItems="center"
          justify="center"
          className={classes.backgroundImage}
        >
          <Typography variant="h3" className={classes.textWhite}>
            <strong>
              MY <span className={classes.textYellow}>ACCOUNT</span>
            </strong>
          </Typography>
        </Grid>
      </Grid>
      <Grid
        container
        direction="column"
        alignItems="center"
        justify="center"
        className={classes.backgroundColor}
      >
        <Grid
          container
          className={classes.mainContainer}
          direction="column"
          alignItems="center"
          justify="center"
        >
          <Button
            variant={'contained'}
            color={'secondary'}
            disabled
            classes={{
              root: classes.placeNewOrder,
              label: classes.placeNewOrderLabel
            }}
            onClick={() => history.push('/order/storage')}
          >
            Place a new order
          </Button>
          <Typography variant={'subtitle1'}>
            Check your email for your Order Confirmation - If you did not receive an email within a few minutes of ordering, please check your Junk Email folder
          </Typography>
          <Grid container item lg={10} className={classes.container}>
            <Grid
              container
              justify="center"
              alignItems="center"
              className={classes.linkContainer}
            >
              <Grid item lg={2} align="center">
                <Typography variant="h6">
                  <Link
                    onClick={() => scrollByContainerId("orders")}
                    className={classes.link}
                  >
                    MY ORDERS
                  </Link>
                </Typography>
              </Grid>
              <Grid item lg={2} align="center">
                <Typography variant="h6">
                  <Link
                    onClick={() => scrollByContainerId("myBins")}
                    className={classes.link}
                  >
                    MY INVENTORY
                  </Link>
                </Typography>
              </Grid>
              <Grid item lg={2} align="center">
                <Typography variant="h6">
                  <Link
                    onClick={() => scrollByContainerId("myInfo")}
                    className={classes.link}
                  >
                    MY INFO
                  </Link>
                </Typography>
              </Grid>
            </Grid>
          </Grid>

          <Grid
            container
            item
            lg={10}
            className={classes.container}
            id="orders"
          >
            {fetchedUserData && (
              <OrdersCard isAdminView={true} userData={fetchedUserData}/>
            )}
          </Grid>

          <Grid
            container
            item
            lg={10}
            className={classes.container}
            id="myBins"
          >
            {fetchedUserData && (
              <MyBinsCard adminUserData={fetchedUserData}/>
            )}
          </Grid>

          <Grid
            container
            item
            lg={10}
            className={`${classes.container} ${classes.spaceBetween}`}
            direction="row"
            alignItems="center"
          >
            <Grid
              container
              item
              md={7}
              xs={12}
              id="myInfo"
              className={classes.infoCard}
            >
              {fetchedUserData && (
                <MyInfoCard
                  disabled
                  adminUserData={fetchedUserData}
                  userFranchise={fetchedUserData?.franchise}
                />
              )}

            </Grid>
            <Grid
              container
              item
              md={4}
              xs={12}
              className={classes.notificationCard}
            >
              {fetchedUserData && (
                <NotificationCard adminUserData={fetchedUserData} disabled/>
              )}
            </Grid>
          </Grid>
        </Grid>
      </Grid>

      {/*<PickUpModal setOpen={setPickUpModalOpen} open={pickUpModalOpen} />*/}

      {/*<DropOffModal setOpen={setDropOffModalOpen} open={dropOffModalOpen} />*/}

    </>
  );
};

export default withStyles(AdminMyAccountPageStyle)(AdminMyAccountPage);
