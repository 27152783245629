import React, {useContext, useEffect, useState} from 'react';
import {
  Grid,
  Button,
  withStyles,
  Typography
} from '@material-ui/core';

import OrderExpandPaneStyle from './OrderExpandPaneStyle';
import CancelAlert from 'components/Common/Alert/CancelAlert';
import {
formatReadableDate
} from 'components/Common/Util/util';
import { formatMyInfoAddress } from 'components/Common/Util/util';
import { UserContext } from "context/user";
import { FINISHED_ORDER_STATUSES } from "components/Common/Util/orderStatusDefinitions";
import moment from 'moment-timezone'

const OrderExpandPane = props => {
  const {
    classes,
    row,
    setFullPickupModalInitialDateTime,
    setOpenFullPickupModal,
    setFullPickupOrderId,
    isAdminView,
  } = props;
  const [isCancelAlert, setIsCancelAlert] = useState(false);
  const [shouldShowFullPickupButton, setShouldShowFullPickupButton] = useState(false);

  console.log('row', row)

  const {userData} = useContext(UserContext);

  // false here means it was never provided, null means it hasn't been processed yet
  const originalBillingDate = row.expandPaneDetails.billing.originalPaymentDate ?
    row.expandPaneDetails.billing.originalPaymentDate :
      row.expandPaneDetails.billing.totalPaymentAmount ? false : null;

  const nextBillingDate = FINISHED_ORDER_STATUSES.includes(row.expandPaneDetails.statusCode) ? 'None'
    : row.expandPaneDetails.billing.nextPaymentDate ? row.expandPaneDetails.billing.nextPaymentDate : 'No payment date set';

  // const nextPaymentAmount = row.expandPaneDetails.billing.totalPaymentAmount ?
  //   `$${parseFloat(row.expandPaneDetails.billing.totalPaymentAmount).toFixed(2)}`
  //   : FINISHED_ORDER_STATUSES.includes(row.expandPaneDetails.statusCode) ? 'None' :
  //   'Payment Not Processed';

  const nextPaymentAmount = FINISHED_ORDER_STATUSES.includes(row.expandPaneDetails.statusCode) ? 'None'
    : row.expandPaneDetails.billing.totalPaymentAmount ?
      `$${parseFloat(row.expandPaneDetails.billing.totalPaymentAmount).toFixed(2)}` : 'Payment Not Processed';

  useEffect(() => {
    let binsRequired = row?.expandPaneDetails?.binsRequired;
    let itemsRequired = row?.expandPaneDetails?.itemsRequired;

    if(binsRequired && itemsRequired) {
      let binCount = binsRequired['27Gallon'] + binsRequired['38Gallon'] + binsRequired['55Gallon'];
      let itemCount = itemsRequired['standard'] + itemsRequired['large'] + itemsRequired['extraLarge'] + itemsRequired['furniture'];

      let pickupsActive = row?.expandPaneDetails?.pickups.filter(pickup => pickup.active === true);

      if(binCount > 0 && pickupsActive.length === 0) {
        setShouldShowFullPickupButton(true);
      }
      else {
        setShouldShowFullPickupButton(false);
      }
    }
  }, [row, userData])

  const openFullPickupModal = () => {
    setOpenFullPickupModal(true);

    let initialDateTime = {
      time: row?.expandPaneDetails?.currentPickupOrDropoff?.timeScheduled,
      date: row?.expandPaneDetails?.currentPickupOrDropoff?.dateScheduled,
    };

    if(initialDateTime?.date) {
      initialDateTime.date = moment(initialDateTime.date);
    }

    setFullPickupModalInitialDateTime({...initialDateTime})
    setFullPickupOrderId(row.orderNo);
  }

  return (
    <Grid container justify="center" className={classes.mainContainer}>
      <Grid container item xs={10} className={classes.orderContainerpadding}>
        <Grid item xs={4} ><Typography variant="h6" className={classes.bodyText}>Current Order:</Typography></Grid>
        <Grid item xs={8}>
          <Grid container item>
            {/* Commented for future use */}
            {/* <Grid container item sm={12} md={6} lg={4}>
              <Button
                className={classes.orderButton}
                onClick={event => window.location.href = '/edit-order/' + row.orderNo}
                disabled={row.orderStatus !== 'Pending'}
              >
                Edit Order
              </Button>
            </Grid> */}
            <Grid container item direction={'row'} sm={12} md={6} lg={6}>
              {shouldShowFullPickupButton &&
                <>
                  <Button
                    className={classes.cancelOrderButton}
                    onClick={openFullPickupModal}
                    disabled={isAdminView}
                  >
                    Place Full Pickup
                  </Button>

                  <Grid item className={classes.bodyTextContainer} style={{'marginBottom': '12px'}}>
                    <span className={classes.bodyText}>*This will automatically apply to all bins in the order</span>
                  </Grid>
                </>

              }

              <Button
                className={classes.cancelOrderButton}
                disabled={row.orderStatus !== 'Pending' || row?.expandPaneDetails?.paymentProcessed === true || isAdminView}
                onClick={() => setIsCancelAlert(true)}
              >
                Cancel Order
              </Button>
            </Grid>
          </Grid>
          <Grid item className={classes.bodyTextContainer}>
            <span className={classes.bodyText}>*Charge will be issued for cancellation within 24 hours of your scheduled appointment time</span>
          </Grid>
        </Grid>
        <Grid item xs={4}>
          <Typography variant="h6" className={`${classes.bodyText} ${classes.appointmentDetails}`} paragraph>
            Appointment Type:
          </Typography>
        </Grid>
        <Grid item xs={8}>
          <Typography variant="h6" className={`${classes.bodyText} ${classes.appointmentDetails}`} paragraph>
            {row.expandPaneDetails.currentPickupOrDropoff ? row.expandPaneDetails.currentPickupOrDropoff.type : 'No current appointment'}
          </Typography>
        </Grid>
        <Grid item xs={4}>
          <Typography variant="h6" className={`${classes.bodyText} ${classes.appointmentDetails}`} paragraph>
            Appointment Date:
          </Typography>
        </Grid>
        <Grid item xs={8}>
          <Typography variant="h6" className={`${classes.bodyText} ${classes.appointmentDetails}`} paragraph>
            {row.expandPaneDetails.currentPickupOrDropoff ? row.expandPaneDetails.currentPickupOrDropoff.readableDateScheduled : 'No current appointment'}
          </Typography>
        </Grid>
        <Grid item xs={4}>
          <Typography variant="h6" className={`${classes.bodyText} ${classes.appointmentDetails}`} paragraph>
            Appointment Time:
          </Typography>
        </Grid>
        <Grid item xs={8}>
          <Typography variant="h6" className={`${classes.bodyText} ${classes.appointmentDetails}`} paragraph>
            {row.expandPaneDetails.currentPickupOrDropoff ? row.expandPaneDetails.currentPickupOrDropoff.readableTimeScheduled : 'No current appointment'}
          </Typography>
        </Grid>

        <Grid item xs={4}>
          <Typography variant="h6" className={`${classes.bodyText} ${classes.appointmentDetails}`} paragraph>
            Address for Delivery
          </Typography>
        </Grid>
        <Grid item xs={8}>
          <Typography variant="h6" className={`${classes.bodyText} ${classes.appointmentDetails}`} paragraph>
            {formatMyInfoAddress(userData)}
          </Typography>
        </Grid>

      </Grid>
      {/* Commented for future use */}
      {/* <Grid container item xs={10} className={classes.orderContainerpadding}>
        <Grid item xs={4}><Typography variant="h6" className={classes.bodyText}>Address for Delivery:</Typography></Grid>
        <Grid item xs={8}>
          <Grid item>
            <Typography variant="h6" className={classes.bodyText}>{row.expandPaneDetails.address}</Typography>
          </Grid>
          <Grid item>
            <Button
              className={classes.orderButton}
              onClick={event => window.location.href = '/edit-order/' + row.orderNo}
              disabled={row.orderStatus !== 'Pending'}
            >
              Change
            </Button>
          </Grid>
          <Grid item className={classes.bodyTextContainer}>
            <span className={classes.bodyText}>*Change or cancel within 24 hours</span>
          </Grid>
        </Grid>
      </Grid> */}
      <Grid container item xs={10} className={classes.snapshotBackground}>
        <Grid container item xs={4} lg={4}>
          <div className={classes.snapshotHeader}>
            <Typography variant="h6" className={classes.snapshotText}>SNAPSHOT</Typography>
          </div>
        </Grid>
        <Grid container item xs={8}>
          <Grid item xs={6}>
            <Grid item >
              <Typography variant="h6" className={classes.bodyText}>Total of each bin:</Typography>
            </Grid>
          </Grid>
          <Grid item xs={6}>
            <Grid item xs>
              <Typography variant="h6" className={classes.bodyText}>Standard Bins - {row.expandPaneDetails.binsRequired['27Gallon']} bins</Typography>
            </Grid>
            <Grid item xs>
              <Typography variant="h6" className={classes.bodyText}>Large Bins - {row.expandPaneDetails.binsRequired['38Gallon']} bins</Typography>
            </Grid>
            <Grid item xs>
              <Typography variant="h6" className={classes.bodyText}>Extra Large Bins - {row.expandPaneDetails.binsRequired['55Gallon']} bins</Typography>
            </Grid>
          </Grid>
          <Grid item xs={6}>
            <Grid item xs>
              <Typography variant="h6" className={classes.bodyText}>Total of each item:</Typography>
            </Grid>
          </Grid>
          <Grid item xs={6}>
            <Grid item xs>
              <Typography variant="h6" className={classes.bodyText}>Standard Items - {row.expandPaneDetails.itemsRequired.standard} items</Typography>
            </Grid>
            <Grid item xs>
              <Typography variant="h6" className={classes.bodyText}>Large Items - {row.expandPaneDetails.itemsRequired.large} items</Typography>
            </Grid>
            <Grid item xs>
              <Typography variant="h6" className={classes.bodyText}>Extra Large Items - {row.expandPaneDetails.itemsRequired.extraLarge} items</Typography>
            </Grid>
            <Grid item xs>
              <Typography variant="h6" className={classes.bodyText}>Large Furniture - {row.expandPaneDetails.itemsRequired.furniture} items</Typography>
            </Grid>
          </Grid>

          <Grid item xs={6}>
            <Grid item xs>
              <Typography variant="h6" className={classes.bodyText}>Original Payment Amount:</Typography>
            </Grid>
          </Grid>
          <Grid item xs={6}>
            <Grid item xs>
              <Typography variant="h6" className={classes.bodyText}>
                {row.expandPaneDetails.billing.totalPaymentAmount ?
                  `$${parseFloat(row.expandPaneDetails.billing.totalPaymentAmount).toFixed(2)}`
                  :
                  'Payment Not Processed'}</Typography>
            </Grid>
          </Grid>

          <Grid item xs={6}>
            <Grid item xs>
              <Typography variant="h6" className={classes.bodyText}>Sales Tax:</Typography>
            </Grid>
          </Grid>
          <Grid item xs={6}>
            <Grid item xs>
              <Typography variant="h6" className={classes.bodyText}>
                {row.expandPaneDetails.billing.additionalTaxedAmount ?
                  `$${parseFloat(row.expandPaneDetails.billing.additionalTaxedAmount).toFixed(2)}`
                  :
                  'Payment Not Processed'}</Typography>
            </Grid>
          </Grid>

          {/*
            Because some orders won't have this since they are old, we check for false here
            Which means that it was never saved and we hide it
          */}
          {originalBillingDate !== false &&
            <>
              <Grid item xs={6}>
                <Grid item xs>
                  <Typography variant="h6" className={classes.bodyText}>Original Billed on Date:</Typography>
                </Grid>
              </Grid>
              <Grid item xs={6}>
                <Grid item xs>
                  <Typography variant="h6" className={classes.bodyText}>
                    {originalBillingDate ? formatReadableDate(originalBillingDate) : 'Payment Not Processed'}
                  </Typography>
                </Grid>
              </Grid>
            </>
          }
          <Grid item xs={6}>
            <Grid item xs>
              <Typography variant="h6" className={classes.bodyText}>Payment Method:</Typography>
            </Grid>
          </Grid>
          <Grid item xs={6}>
            <Grid item xs>
              <Typography variant="h6" className={classes.bodyText}>{row.expandPaneDetails.billing.paymentMethodType ?? 'Payment Not Processed'}</Typography>
            </Grid>
          </Grid>

          <Grid item xs={6}>
            <Grid item xs>
              <Typography variant="h6" className={classes.bodyText}>Next Billing Date:</Typography>
            </Grid>
          </Grid>
          <Grid item xs={6}>
            <Grid item xs>
              <Typography variant="h6" className={classes.bodyText}>
                {nextBillingDate}
              </Typography>
            </Grid>
          </Grid>

          <Grid item xs={6}>
            <Grid item xs>
              <Typography variant="h6" className={classes.bodyText}>Next Payment Amount:</Typography>
            </Grid>
          </Grid>
          <Grid item xs={6}>
            <Grid item xs>
              <Typography variant="h6" className={classes.bodyText}>
                {nextPaymentAmount}
              </Typography>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
      <Grid container justify="center" className={classes.orderContainerpadding}>
        <Grid item xs align="center">
          <a href={row.expandPaneDetails.billing.stripeReceiptLink} className={classes.receiptLink}>
            <Button className={classes.printButton} disabled={row.expandPaneDetails.billing.stripeReceiptLink === null}>View/Print Receipt</Button>
          </a>
        </Grid>
      </Grid>
      <CancelAlert isCancelAlert={isCancelAlert} setIsCancelAlert={setIsCancelAlert} orderId={row.orderNo} />
    </Grid>
  );
};

export default withStyles(OrderExpandPaneStyle)(OrderExpandPane);
