import { MuiTheme, button, breakpoints } from "assets/theme";
import bgImage from "../../assets/img/my_account/my_account_bg.jpg";

const MyAccountPageStyle = {
  backgroundImage: {
    minHeight: "300px",
    backgroundColor: "grey",
    backgroundImage: `url(${bgImage})`,
    backgroundSize: "cover",
    backgroundPosition: '0% 50%'
  },
  backgroundColor: {
    backgroundColor: MuiTheme.palette.background.default,
  },
  placeNewOrder: {
    padding: '10px 20px',
    marginBottom: '30px',
    fontSizeL: '2rem'
  },
  placeNewOrderLabel: {
    fontSize: '1.2rem'
  },
  mainContainer: {
    width: "95%",
    margin: "30px auto",
  },
  container: {
    minHeight: "120px",
    justifyContent: "center",
  },
  actionOuterContainer: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'flex-start',
    margin: '15px 0'
  },
  actionInnerContainer: {
    margin: '35px 0'
  },
  actionContainerText: {
    fontSize: '19px',
    width: '100%',
    marginRight: 'auto'
  },
  actionContainerTextRed: {
    fontSize: '19px',
    width: '100%',
    marginRight: 'auto',
    color: '#D62704'
  },
  underlinedText: {
    textDecoration: 'underline'
  },
  sendBinButton: {
    ...button,
    maxWidth: "400px",
    margin: "0",
    borderRadius: "0",
    padding: "7px 20px",
    "&:hover": {
      background: MuiTheme.palette.primary.hover,
    },
    background: MuiTheme.palette.common.black,
    color: MuiTheme.palette.secondary.main,
  },
  sendBinBackButton: {
    ...button,
    maxWidth: "400px",
    margin: "0",
    padding: "7px 20px",
    borderRadius: "0",
    "&:hover": {
      background: MuiTheme.palette.primary.hover,
    },
    background: MuiTheme.palette.common.black,
    color: MuiTheme.palette.secondary.main,
  },
  textWhite: {
    color: MuiTheme.palette.common.white,

    "& strong": {
      color: MuiTheme.palette.common.white,
    },
  },
  textYellow: {
    color: MuiTheme.palette.secondary.main,
  },
  link: {
    cursor: "pointer",
  },
  notificationCard: {
    marginTop: "40px",
  },
  infoCard: {
    marginTop: "30px",
  },
  linkContainer: {
    height: "100px",
    marginTop: "1%",
    justifyContent: "space-around",
    width: "60%",
  },
  btnContainer: {
    padding: "10px",
  },
  cardContainer: {
    minHeight: "240px",
    justifyContent: "",
  },
  spaceBetween: {
    justifyContent: "space-between",
  },
  [breakpoints.small]: {
    linkContainer: {
      width: "100%",
    },
  },
};

export default MyAccountPageStyle;
