/* eslint-disable */
import React, { useState } from 'react';
import { pdfjs } from 'react-pdf';
import {
  withStyles,
  Modal,
  Card,
  Typography
} from '@material-ui/core';
import FooterModalStyle from './FooterModalStyle';
import CloseIcon from '@material-ui/icons/Close';
import PDFViewer from 'pdf-viewer-reactjs';

// required workaround for this library
pdfjs.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.js`;

import { Document, Page } from 'react-pdf/dist/entry.webpack';
import PaginationChevronCountPanel from '../../Common/PaginationChevronCountPanel/PaginationChevronCountPanel';

const FooterModal = (props) => {
  const {
    classes,
    title,
    open,
    closeFunc,
    file
  } = props;

  const [pageNumber, setPageNumber] = useState(1);
  const [numPages, setNumPages] = useState(1);

  const onDocLoadSuccess = ({ numPages }) => {
    setNumPages(numPages)
  };

  const updatePage = (isNextPage) => {
    if(isNextPage && pageNumber <= numPages-1) {
      setPageNumber(pageNumber+1);
    }
    else if(!isNextPage && pageNumber > 1) {
      setPageNumber(pageNumber-1);
    }
  };

  return (
    <Modal open={open} className={classes.modal}>
      <Card className={classes.card}>
        <div className={classes.closeIcon} onClick={closeFunc}>
          <CloseIcon/>
        </div>

        <Typography variant={"h4"}>{title}</Typography>

        <div className={classes.pdfCanvas}>
          <Document
            file={file}
            error={"error"}
            onLoadSuccess={onDocLoadSuccess}

          >
            <Page
              pageNumber={pageNumber}
              className={classes.pdfViewer}
              scale={1}
            />
          </Document>
        </div>

        <div className={classes.paginationPanel}>
          <PaginationChevronCountPanel
            pageIndex={pageNumber}
            pageCount={numPages}
            updatePageIndex={updatePage}
          />
        </div>
      </Card>
    </Modal>
  )
};

export default withStyles(FooterModalStyle)(FooterModal);
