import React, { useContext, useEffect, useState } from 'react';
import { Checkbox, FormControlLabel, Grid, Typography, withStyles } from '@material-ui/core';

import StorageCard from '../StorageCard/StorageCard';
import MyBinsCardStyle from './MyBinsCardStyle';
import { UserContext } from "context/user";
import PaginationChevronCountPanel from 'components/Common/PaginationChevronCountPanel/PaginationChevronCountPanel';
import { isSame } from "components/Common/Util/util";

const MyBinsCard = ({ classes, adminUserData }) => {
  const context = useContext(UserContext);
  // Use propUserData if provided, otherwise fallback to userData from context
  const userData = adminUserData || context.userData;
  const setUserData = context.setUserData;
  const selectedBins = context.selectedBins;
  const setSelectedBins = context.setSelectedBins;
  const selectedItems = context.selectedItems;
  const setSelectedItems = context.setSelectedItems;

  const isAdminView = adminUserData !== undefined;

  console.log('user data', userData);
  console.log('user context', context);

  const [showStorageBins, setShowStorageBins] = useState(true);
  const [storageBinsItemsArray, setStorageBinsItemsArray] = useState([]);
  const [rentBinsItemsArray, setRentBinsItemsArray] = useState([]);
  const [binsItemsArray, setBinsItemsArray] = useState([]);
  const [page, setPage] = useState({ pageIndex: 1, pageCount: 1 });
  const [allSelected, setAllSelected] = useState(true);

  useEffect(() => {
    filterStorageBinsItems();
  }, [userData]);

  useEffect(() => {
    setPageIndexAndCount();
    clearSelectedBinsArray();
  }, [storageBinsItemsArray, showStorageBins]);

  useEffect(() => {
    const mergeArr = storageBinsItemsArray.concat(rentBinsItemsArray);
    console.log('merge arr', mergeArr);

    setBinsItemsArray([...mergeArr]);

    if(!isAdminView) {
      selectAllBinsAndItems(mergeArr);
    }
  }, [storageBinsItemsArray, rentBinsItemsArray]);

  useEffect(() => {
    setAllSelected(isSame(selectedBins.concat(selectedItems), convertBinsItemsToIdArray(binsItemsArray)))
  }, [selectedItems, selectedBins, binsItemsArray]);

  const selectAllBinsAndItems = (binsItems) => {
    let selectedBins = convertBinsItemsToIdArray(binsItems.filter(elem => elem.binType !== undefined));
    let selectedItems = convertBinsItemsToIdArray(binsItems.filter(elem => elem.itemType !== undefined));

    setSelectedBins(selectedBins);
    setSelectedItems(selectedItems);
  };

  const deselectAllBinsAndItems = () => {
    setSelectedBins([]);
    setSelectedItems([]);
  };

  const clearSelectedBinsArray = () => {
    setSelectedBins([]);
  };

  const convertBinsItemsToIdArray = (binsItems) => {
    return binsItems.map(i => i.id);
  };

  const filterStorageBinsItems = () => {
    if (userData.orders.length > 0) {
      const storageData = userData.orders.filter(order => order.userReadableStatus !== 'Complete').map(order => {
        return [{
          binsItems: [...order.bins.filter(bin => !bin.rental), ...order.items.filter(item => !item.rental)],
          orderId: order.id
        }];
      });

      console.log('storage data', storageData)

      modifyStorageArray(storageData);
    }
  };

  const modifyStorageArray = (storageBinsItems) => {
    const storageBinsItemsFlatArray = [].concat(...storageBinsItems);
    let storageBinsItemsArray = [];
    storageBinsItemsFlatArray.forEach(obj => {
      obj.binsItems.forEach(binItem => {
        binItem.orderId = obj.orderId;
        storageBinsItemsArray.push(binItem);
      });
    });

    setStorageBinsItemsArray(storageBinsItemsArray);
  };

  const updateClickedBins = (bin, isSelected) => {
    if (isSelected) {
      if (bin.itemType) {
        setSelectedItems(prev => [...prev, bin.id]);
      } else if (bin.binType) {
        setSelectedBins(prev => [...prev, bin.id]);
      }
    } else {
      if (bin.itemType) {
        setSelectedItems(prev => prev.filter(id => id !== bin.id));
      } else if (bin.binType) {
        setSelectedBins(prev => prev.filter(id => id !== bin.id));
      }
    }
  };

  const setPageIndexAndCount = () => {
    const count = Math.ceil((showStorageBins ? storageBinsItemsArray.length : rentBinsItemsArray.length) / 6);
    setPage({ pageIndex: 1, pageCount: count });
  };

  const updatePageIndex = (increment) => {
    setPage(prev => ({
      ...prev,
      pageIndex: increment ? Math.min(prev.pageIndex + 1, prev.pageCount) : Math.max(prev.pageIndex - 1, 1)
    }));
  };

  return (
    <>
      <Grid container className={classes.margin}>
        <Grid container alignItems="center">
          <Grid item className={classes.mainHeader}>
            <Typography variant="h5">MY INVENTORY</Typography>
          </Grid>
        </Grid>
        <Grid container className={classes.body}>
          {binsItemsArray.length > 0 &&
            <Grid container item xs={12} className={classes.selectAllContainer}>
              <FormControlLabel
                control={
                  <Checkbox
                    checked={allSelected}
                    disabled={isAdminView}
                    onChange={() => {
                      allSelected ? deselectAllBinsAndItems() : selectAllBinsAndItems(binsItemsArray);
                      setAllSelected(!allSelected);
                    }}
                  />
                }
                label={'Select All'}
              />
            </Grid>
          }
          <Grid container>
            {binsItemsArray.length > 0 ?
              binsItemsArray.slice((page.pageIndex - 1) * 6, page.pageIndex * 6).map((bin, i) => (
                <StorageCard
                  key={i}
                  updateClickedBins={updateClickedBins}
                  bin={bin}
                  orderId={bin.orderId}
                  isAdminView={isAdminView}
                  selected={selectedBins.includes(bin.id) || selectedItems.includes(bin.id)}
                />
              )) : (
                <Grid container item xs className={classes.noBins} direction={'column'} justify={'flex-start'} alignItems={'center'}>
                  <Typography variant="h5" align={'center'} paragraph>Your belongings will appear here after your appointment</Typography>
                </Grid>
              )}
          </Grid>
          <Grid container className={classes.paginationPanel}>
            <PaginationChevronCountPanel pageIndex={page.pageIndex} pageCount={page.pageCount} updatePageIndex={updatePageIndex} />
          </Grid>
        </Grid>
      </Grid>
    </>
  );
};

export default withStyles(MyBinsCardStyle)(MyBinsCard);
