import React, { useState, useContext, useEffect } from 'react';
import { withStyles } from '@material-ui/core/styles';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Paper from '@material-ui/core/Paper';
import Collapse from "@material-ui/core/Collapse";

import OrderExpandPane from '../OrderExpandPane/OrderExpandPane';
import CurrentOrderPaneStyle from './CurrentOrderPaneStyle';
import { UserContext } from "context/user";
import ExpandLessIcon from '@material-ui/icons/ExpandLess';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import PickUpModal from "components/MyAccount/PickUpModal/PickUpModal";
import moment from "moment-timezone";
import djangoService from "services/djangoService";

function createData(orderNo, detail, binsOrdered, itemsOrdered, orderStatus, expandPaneDetails) {
  return { orderNo, detail, binsOrdered, itemsOrdered, orderStatus, expandPaneDetails };
}

const ExpandingRow = (props) => {
  const {
    row,
    classes,
    rowOpen,
    setRowOpen,
    setFullPickupModalInitialDateTime,
    setOpenFullPickupModal,
    setFullPickupOrderId,
    isAdminView=false,
  } = props;

  const setStatusColor = (status) => {
    if (status === 'Complete')
      return 'green';
    else if (status === 'Pending')
      return '#FFE001';
    else
      return 'red';
  }

  return (
    <>
      <TableRow key={row.id} onClick={() => {
        setRowOpen(prevValue => prevValue !== row.orderNo ? row.orderNo : null);
      }}>
        <TableCell align={'left'} className={classes.expandIconCell}>
          {row.orderNo === rowOpen ? <ExpandLessIcon size={'small'}/> : <ExpandMoreIcon size={'small'}/>}
        </TableCell>
        <TableCell component="th" scope="row" align="center" className={classes.tableBodyCell}>
          {row.orderNo}
        </TableCell>
        <TableCell align="center" className={classes.tableBodyCell}>{row.binsOrdered}</TableCell>
        <TableCell align="center" className={classes.tableBodyCell}>{row.itemsOrdered}</TableCell>
        <TableCell align="center" style={{ color: setStatusColor(row.orderStatus) }} className={classes.tableBodyCell}>
          {row.orderStatus}
        </TableCell>
      </TableRow>
      <TableRow>
        <TableCell padding={'none'} colSpan={8}>
          <Collapse hidden={!(row.orderNo === rowOpen)} in={row.orderNo === rowOpen}>
            <OrderExpandPane
              row={row}
              setFullPickupModalInitialDateTime={setFullPickupModalInitialDateTime}
              setOpenFullPickupModal={setOpenFullPickupModal}
              setFullPickupOrderId={setFullPickupOrderId}
              isAdminView={isAdminView}
            />
          </Collapse>
        </TableCell>
      </TableRow>
    </>
  );
};

const CurrentOrderPane = (props) => {
  const { classes, adminUserData, isAdminView } = props;
  // Use adminUserData if provided, otherwise fallback to userData from context
  const {userData: contextUserData, setUserData} = useContext(UserContext);
  const userData = adminUserData || contextUserData;

  console.log('current order pane props', props);

  const [rows, setRows] = useState([]);
  const [rowOpen, setRowOpen] = useState(null);

  const [openFullPickupModal, setOpenFullPickupModal] = useState(false);
  const [fullPickupModalInitialDateTime, setFullPickupModalInitialDateTime] = useState({
    date: moment(),
    time: '00:00:00'
  });
  const [fullPickupOrderId, setFullPickupOrderId] = useState(null);

  useEffect(() => {
    if (userData && userData.orders) {
      let binsItemsArray = userData.orders.map(order => {
        let binsRequired = order.binsRequired['27Gallon'] + order.binsRequired['38Gallon'] + order.binsRequired['55Gallon'];
        let itemsRequired = order.itemsRequired.standard + order.itemsRequired.large + order.itemsRequired.extraLarge;
        return createData(order.id, order.deliveryNotes, `${binsRequired} bins`, `${itemsRequired} items`, order.userReadableStatus, order);
      });
      setRows(binsItemsArray);
    }
  }, [userData]);

  const scheduleFullPickup = (pickupData) => {
    let formattedPickupData = {
      ...pickupData,
      fullPickup: true,
      orderId: fullPickupOrderId
    };

    djangoService.Pickup.create(formattedPickupData)
      .then(response => {
        console.log('response success', response);
        setOpenFullPickupModal(false);

        setUserData({
          ...userData,
          orders: userData.orders.map(order => {
            if (order.id === fullPickupOrderId) {
              return {
                ...order,
                pickups: [
                  ...order.pickups,
                  {
                    ...response.data,
                  }
                ]
              };
            }
            return order;
          })

        })
      })
      .catch(error => {
        console.log('error scheduling full pickup', error);
      });
  }

  return (
    <>
      <TableContainer component={Paper}>
        <Table aria-label="simple table" className={classes.tableRow}>
          <TableHead>
            <TableRow className={classes.tableRowHeader}>
              <TableCell/>
              <TableCell align="center" className={classes.tableHeaderCell}>Order No</TableCell>
              <TableCell align="center" className={classes.tableHeaderCell}>Bins Ordered</TableCell>
              <TableCell align="center" className={classes.tableHeaderCell}>Items Ordered</TableCell>
              <TableCell align="center" className={classes.tableHeaderCell}>Order Status</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {rows.length > 0 ? rows.map(row => (
              <ExpandingRow
                key={row.orderNo}
                row={row}
                classes={classes}
                rowOpen={rowOpen}
                setRowOpen={setRowOpen}
                setFullPickupModalInitialDateTime={setFullPickupModalInitialDateTime}
                setOpenFullPickupModal={setOpenFullPickupModal}
                setFullPickupOrderId={setFullPickupOrderId}
                isAdminView={isAdminView && adminUserData !== undefined}
              />
            )) : (
              <TableRow>
                <TableCell align="center" colSpan={5}>NO ORDERS</TableCell>
              </TableRow>
            )}
          </TableBody>
        </Table>
      </TableContainer>

      <PickUpModal
        fullPickup={true}
        open={openFullPickupModal}
        setOpen={setOpenFullPickupModal}
        orderPlacedPickup={false}
        orderId={fullPickupOrderId}
        data={{
          onFinish: scheduleFullPickup,
          pickup_time: fullPickupModalInitialDateTime.time,
          pickup_date: fullPickupModalInitialDateTime.date
        }}
      />
    </>
  );
}

export default withStyles(CurrentOrderPaneStyle)(CurrentOrderPane);
