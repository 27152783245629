import React from 'react';
import Snackbar from '@material-ui/core/Snackbar';
import MuiAlert from '@material-ui/lab/Alert';
import { makeStyles } from '@material-ui/core/styles';

function Alert(props) {
    return <MuiAlert elevation={6} variant="filled" {...props} />;
}

const useStyles = makeStyles(theme => ({
    root: {
        width: '100%',
        position: 'absolute',
        '& > * + *': {
            marginTop: theme.spacing(2),
        },
    },
}));

const SnackbarAlert = ({ showAlert, setShowAlert }) => {
    const classes = useStyles();

    const handleClose = (event, reason) => {
        if (reason === 'clickaway') {
            return;
        }

        setShowAlert({ ...showAlert, open: false });
    };

    return (
        <div className={classes.root}>
            <Snackbar open={showAlert.open} autoHideDuration={6000} onClose={handleClose} anchorOrigin={{ vertical: 'top', horizontal: 'right' }}>
                <Alert onClose={handleClose} severity={showAlert.severity}>
                    {showAlert.message}
                </Alert>
            </Snackbar>
        </div>
    );
}

export default SnackbarAlert;
