import axios from 'axios';
import Cookies from 'universal-cookie';

import APIConfig from 'settings';
import snakecaseKeys from 'snakecase-keys';
import camelcaseKeys from 'camelcase-keys';

const responseBody = (response) => camelcaseKeys(response, {deep: true});
const responseError = (error) => {
  console.error(error);
  throw error;
};

axios.defaults.baseURL = APIConfig.DJANGO_API_BASE_URL;

// token interceptor
axios.interceptors.request.use(
  (config) => {
    const cookies = new Cookies();
    const token = cookies.get('token');
    if (token) config.headers.Authorization = `Token ${token}`;
    return config;
  },
  (error) => {
    console.log('response rejected', error)
    return Promise.reject(error);
  },
);

// error interceptors
axios.interceptors.response.use(undefined, (error) => {
  const cookies = new Cookies();
  const status = error.response ? error.response.status : null;
  const data = error.response ? error.response.data : null;
  if (status === 403 && data && data['detail'] === 'Invalid token.') {
    console.log('cookie removed due to invalid token');
    cookies.remove('token', { path: '/' });
    // this was causing infinite loops with stale cookies
    //window.location.reload();
  }
  throw error;
});

const requests = {
  get: (url) => axios.get(url).then(responseBody).catch(responseError),

  post: (url, body={}, headers={}, snakeCase=true) =>
    axios.post(url, snakeCase ? snakecaseKeys(body, { deep: true }) : body, {...headers})
      .then(responseBody).catch(responseError),

  put: (url, body) => axios.put(url, snakecaseKeys(body, { deep: true })).then(responseBody).catch(responseError),

  delete: (url) => axios.delete(url).then(responseBody).catch(responseError),

  patch: (url, body) => axios.patch(url, snakecaseKeys(body, { deep: true })).then(responseBody).catch(responseError)
};

const Order = {
  getValidZipCodes: (franchiseCity) => requests.get(`/valid-zip-codes/?franchise=${franchiseCity}`),
  getAvailableTimes: (date, franchiseCity) => requests.post(`/pickups/available-times/?franchise=${franchiseCity}`, {
    date
  }),
  create: (values) => requests.post('/orders/', {
    ...values
  }),
  cancel: (orderId) => requests.post(`/orders/${orderId}/cancel/`, {})
}

const Dropoff = {
  create: (values) => requests.post('/dropoffs/', {
    ...values
  })
}

const Pickup = {
  create: (values) => requests.post('/pickups/', {
    ...values
  })
}

const CouponCode = {
  checkCode: (code, city) => requests.post('/check-coupon-code/', {couponCode: code, city})
}

const User = {
  register: (values) => requests.post('/users/', {
    ...values
  }),
  authenticate: (email, password) => requests.post('/users/authenticate/', {
    email,
    password
  }),
  get: () => requests.get('/users/me/'),
  getByUuid: (uuid) => requests.get(`/users/get-by-uuid/?uuid=${uuid}`),
  requestPasswordReset: (email) => requests.post('/users/me/request-password-reset/', {
    email
  }),
  verifyPasswordResetToken: (passwordResetToken) => requests.post('/users/me/verify-password-token/', {
    token: passwordResetToken
  }),
  confirmPasswordReset: (password, passwordResetToken) => requests.post('/users/me/confirm-password/', {
    token: passwordResetToken,
    password
  }),
  update: (values) => requests.patch('/users/me/', {
    ...values
  }),
  logout: (history) => {
    const cookies = new Cookies();
    cookies.remove('token');
    cookies.remove('user');
    delete axios.defaults.headers.common['Authorization'];
    history.replace('/login')
  }
};

export default {
  User,
  Order,
  Dropoff,
  Pickup,
  CouponCode
};
