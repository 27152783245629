import { MuiTheme, button, fontFamilyMontserrat, breakpoints } from 'assets/theme';

const OrderExpandPaneStyle = {
  mainContainer: {
    backgroundColor: MuiTheme.palette.common.white,
    border: '1px solid black'
  },
  snapshotHeader: {
    background: MuiTheme.palette.secondary.contrastText,
    color: MuiTheme.palette.secondary.main,
    height: '60px',
    width: '150px',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center'
  },
  snapshotBackground: {
    border: '1px solid black',
    height: '600px',
    paddingTop: '20px'
  },
  orderButton: {
    ...button,
    margin: '0',
    maxWidth: '200px',
    minWidth: '140px',
    height: '50px',
    borderRadius: '0',
    '&:hover': {
      background: MuiTheme.palette.secondary.dark
    },
    background: MuiTheme.palette.secondary.main,
    color: MuiTheme.palette.secondary.contrastText,
    ...fontFamilyMontserrat
  },
  appointmentDetails: {
    marginTop: '35px'
  },
  cancelOrderButton: {
    ...button,
    margin: '0',
    maxWidth: '200px',
    minWidth: '140px',
    // marginLeft: '15px',
    height: '50px',
    borderRadius: '0',
    '&:hover': {
      background: MuiTheme.palette.secondary.dark
    },
    background: MuiTheme.palette.secondary.main,
    color: MuiTheme.palette.secondary.contrastText,
    ...fontFamilyMontserrat
  },
  placeFullPickupButton: {
    ...button,
    margin: '0',
    marginBottom: '14px',
    maxWidth: '200px',
    minWidth: '140px',
    // marginLeft: '15px',
    height: '50px',
    borderRadius: '0',
    '&:hover': {
      background: MuiTheme.palette.secondary.dark
    },
    background: MuiTheme.palette.secondary.main,
    color: MuiTheme.palette.secondary.contrastText,
    ...fontFamilyMontserrat
  },
  receiptLink: {
    textDecoration: 'none',
    color: 'inherit'
  },
  printButton: {
    ...button,
    margin: '0',
    maxWidth: '300px',
    height: '70px',
    borderRadius: '0',
    '&:hover': {
      background: MuiTheme.palette.secondary.dark
    },
    background: MuiTheme.palette.secondary.main,
    color: MuiTheme.palette.secondary.contrastText,
    ...fontFamilyMontserrat
  },
  orderContainerpadding: {
    padding: '50px 0'
  },
  bodyText: {
    ...fontFamilyMontserrat,
    color: MuiTheme.palette.common.gray,
  },
  bodyTextNoWrap: {
    ...fontFamilyMontserrat,
    color: MuiTheme.palette.common.gray,
  },
  snapshotText: {
    ...fontFamilyMontserrat,
    color: MuiTheme.palette.secondary.main,
  },
  bodyTextContainer: {
    marginTop: '5px'
  },
  [breakpoints.medium]: {
    // cancelOrderButton: {
    //     marginTop: '20px',
    //     marginLeft: '0px'
    // },
    bodyText: {
      fontSize: '12pt'
    },
  },
  [breakpoints.extraSmall]: {
    bodyText: {
      fontSize: '10pt'
    },
    snapshotText: {
      fontSize: '10pt'
    },
    snapshotHeader: {
      width: '80px',
    },
  }
}

export default OrderExpandPaneStyle;
