import React, {useContext, useEffect} from 'react';
import Cookies from 'universal-cookie';
import { Redirect } from 'react-router-dom';
import {UserContext} from "context/user";

const Logout = () => {
    const cookies = new Cookies();
    const { setUserData } = useContext(UserContext);

    useEffect(() => {
        cookies.remove('token');
        cookies.remove('user')
        cookies.remove('isLoggedInStep3');
        cookies.remove('initialPickUp');

        setUserData(null);

        console.log('cookies removed')
        console.log('logged out')
    }, []);

    return (
        <Redirect to={'/'} />
    )
}

export default Logout;
