import React, { useContext, useEffect, useState } from 'react';
import { Link, useLocation } from 'react-router-dom';
import {
  withStyles,
  Typography,
  AppBar,
  Container,
  Toolbar,
  Button,
  IconButton,
  Drawer,
  List,
  ListItem,
  ListItemText,
  Divider,
} from '@material-ui/core';
import MenuIcon from '@material-ui/icons/Menu';

import NavBarStyle from './NavBarStyle';
import logoNav from 'assets/img/shared/logo-nav.png';
import NavBarMessageButton from './NavBarMessageButton/NavBarMessageButton';
import {UserContext} from "context/user";
import cn from "classnames";

const NavBar = props => {
  const { classes } = props;
  let location = useLocation();
  const { userData } = useContext(UserContext);
  const [drawerOpen, setDrawerOpen] = useState(false);
  const [links, setLinks] = useState([
    {
      text: 'STORAGE',
      ref: '/order/storage'
    },
    // {
    //   text: 'RENT',
    //   ref: '/order/rent'
    // },
    {
      text: 'ABOUT US',
      ref: '/about'
    },
    {
      text: 'FAQs',
      ref: '/faq'
    }
  ]);

  useEffect(() => {
    if (userData) {
      console.log('set links1', [...links.filter(link => link.text !== 'LOG OUT'), { text: 'LOG OUT', ref: '/logout' }])
      setLinks([...links.filter(link => link.text !== 'LOG OUT'), { text: 'LOG OUT', ref: '/logout' }])
    }
    else {
      console.log('set links2', links.filter(link => link.text !== 'LOG OUT'))
      setLinks(links.filter(link => link.text !== 'LOG OUT'))
    }
  }, [userData]);

  const toggleDrawer = open => event => {
    if (
      event.type === 'keydown' &&
      (event.key === 'Tab' || event.key === 'Shift')
    ) {
      return;
    }

    setDrawerOpen(open);
  };

  return (
    <div className={classes.root}>
      <AppBar position="static" className={classes.nav}>
        <Container maxWidth={false}>
          <Toolbar>

            <div className={classes.alignLeft}>
              <Link to={'/'}>
                <img src={logoNav} className={classes.logo} alt={'Logo'} />
              </Link>

              <div className={cn(classes.phoneNumberContainer, classes.tucsonPhoneNumberContainer)}>
                <Typography variant={'subtitle1'} color={'secondary'} className={classes.cityName}>
                  Tucson
                </Typography>
                <Typography variant={'subtitle1'} color={'secondary'}>
                  <a href={"tel:+1-520-276-6906"} className={classes.phoneNumber}>
                    (520) 276-6906
                  </a>
                </Typography>
              </div>

              <div className={classes.phoneNumberContainer}>
                <Typography variant={'subtitle1'} color={'secondary'} className={classes.cityName}>
                  Austin
                </Typography>
                <Typography variant={'subtitle1'} color={'secondary'}>
                  <a href={"tel:+1-520-276-6906"} className={classes.phoneNumber}>
                    (512) 814-5669
                  </a>
                </Typography>
              </div>

            </div>

            <Typography variant={'body2'} className={classes.navLinkContainer}>
              {links.map(({ text, ref }, index) => (
                <Link to={ref} className={location.pathname === ref ? classes.activeLink : classes.navLink} key={index}>
                  {text}
                </Link>
              ))}
            </Typography>
            <Button color="primary" className={classes.accountButton} href="/my-account">
              My Account
            </Button>

            {/* Hamburger Button */}
            <IconButton
              edge="start"
              className={classes.menuButton}
              color="secondary"
              onClick={toggleDrawer(true)}
            >
              <MenuIcon />
            </IconButton>
            {/* --Hamburger Button */}

            <NavBarMessageButton />
          </Toolbar>

          {/* Mobile Drawer */}
          <Drawer
            open={drawerOpen}
            onClose={toggleDrawer(false)}
            className={classes.drawer}
          >
            <div
              className={classes.drawerList}
              role="presentation"
              onClick={toggleDrawer(false)}
              onKeyDown={toggleDrawer(false)}
            >
              <List>
                <ListItem button component={Link} to={'/'}>
                  <ListItemText primary={'GET STARTED'} />
                </ListItem>
                {links.map(({ text, ref }, index) => (
                  <ListItem button component={Link} to={ref} key={index}>
                    <ListItemText primary={text} />
                  </ListItem>
                ))}
                <Divider />

                <Link to={'/my-account'} style={{ color: 'inherit', textDecoration: 'none' }}>
                  <ListItem button>
                    <ListItemText primary={'MY ACCOUNT'} />
                  </ListItem>
                </Link>
              </List>
            </div>
          </Drawer>
          {/* --Mobile Drawer */}
        </Container>
      </AppBar>
    </div>
  );
};

export default withStyles(NavBarStyle)(NavBar);
