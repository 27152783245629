import React from 'react';
import { fade, useTheme } from '@material-ui/core/styles';

const StripeInput = props => {
  const {
    component: Component,
    inputRef,
    'aria-invalid': ariaInvalid,
    'aria-describedby': ariaDescribeBy,
    defaultValue,
    required,
    onKeyDown,
    onKeyUp,
    readOnly,
    autoComplete,
    autoFocus,
    type,
    name,
    rows,
    ...other
  } = props;
  const theme = useTheme();
  const [mountNode, setMountNode] = React.useState(null);

  React.useImperativeHandle(
    inputRef,
    () => ({
      focus: () => mountNode.focus()
    }),
    [mountNode]
  );

  return (
    <Component
      onReady={setMountNode}
      style={{
        base: {
          color: theme.palette.text.primary,
          fontSize: `${theme.typography.fontSize}px`,
          '::placeholder': {
            color: fade(theme.palette.text.primary, 0.42)
          },
          textAlign: 'left'
        },
        invalid: {
          color: theme.palette.text.error
        }
      }}
      {...other}
    />
  );
};

export default StripeInput;
