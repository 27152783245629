import React from 'react';
import PropTypes from 'prop-types';

import TextField from "@material-ui/core/TextField";
import StripeInput from "components/Common/Form/StripeInput";

const StripeField = props => {
  const {
    classes,
    stripeComponent,
    InputProps,
    onChange,
    error,
    helperText,
    setFieldError,
    setFieldValue,
    ...rest
  } = props;

  const handleChange = change => {
    const { value, error, complete } = change;

    if (!!error) {
      setFieldError(props.name, error.message);
    }
    else if(complete) {
      setFieldValue(props.name, value);
    }

    // NOTE:
    // Stripe sensitive inputs like card number do not ever give a value
    // Stripe will send errors and process the token when appropriate so you can ignore validation
    // You still need setFieldValue though to make it controlled
  };

  return (
    <TextField
      {...rest}
      InputProps={{
        ...InputProps,
        inputProps: {
          component: stripeComponent
        },
        inputComponent: StripeInput
      }}
      onChange={handleChange}
      helperText={error}
      error={!!error}
    />
  );
};

StripeField.propTypes = {
  classes: PropTypes.object,
  setFieldError: PropTypes.func.isRequired,
  stripeComponent: PropTypes.func.isRequired,
  setFieldValue: PropTypes.func.isRequired,
};

export default StripeField;
