import { MuiTheme, fontFamilyMontserrat } from 'assets/theme';

const BinsItemsDetailsCardStyle = {
    container: {
        flex: 1,
        width: '100%',
        flexDirection: 'column',
        justifyContent: 'flex-start',
        alignItems: 'center'
    },
    tableHeaderCell: {
        fontSize: '15pt',
        color: MuiTheme.palette.secondary.main,
    },
    tableBodyCell: {
        ...fontFamilyMontserrat,
        fontSize: '12pt',
        color: MuiTheme.palette.common.gray,
    },
    tableRowHeader: {
        background: MuiTheme.palette.common.black,
    },
    noBinsItems: {
        width: '100%',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        padding: '20px 0',
        backgroundColor: 'white'
    }
};

export default BinsItemsDetailsCardStyle;
