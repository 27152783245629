import { MuiTheme, fontFamilyMontserrat, breakpoints } from 'assets/theme';

const MyInfoCardStyle = {
    mainHeader: {
        width: '40%',
        minHeight: '70px',
        backgroundColor: MuiTheme.palette.secondary.main,
        padding: '20px 0 0 25px'
    },
    body: {
        border: '1px solid grey',
        padding: '0 20px',
        backgroundColor: MuiTheme.palette.common.white,
        minHeight: '420px',
    },
    bodyText: {
        ...fontFamilyMontserrat,
        color: MuiTheme.palette.common.gray,
        fontSize: '17px',
        fontWeight: '400',
        wordBreak: 'keep-all',
        paddingRight: '5px'
    },
    emailText: {
        ...fontFamilyMontserrat,
        color: MuiTheme.palette.common.gray,
        fontSize: '17px',
        fontWeight: '400',
        wordBreak: 'break-all',
        paddingRight: '5px'
    },
    editText: {
        color: MuiTheme.palette.secondary.main,
        cursor: 'pointer'
    },
    squareBracket: {
        color: MuiTheme.palette.common.black
    },
    margin: {
        marginTop: '5%'
    },
    [breakpoints.small]: {
        bodyText: {
            fontSize: '15px'
        },
    }
}

export default MyInfoCardStyle;
