import React, { createContext, useState, useEffect } from 'react';
import axios from 'axios';
import camelcaseKeys from 'camelcase-keys';
import APIConfig from '../settings';
import { withRouter } from 'react-router-dom';

export const OrderContext = createContext();

export const OrderProvider = ({ initialBinState, initialFormState, children }) => {
    // const [bins, setBins] = useState(initialBinState);
    // const [formValues, setFormValues] = useState(initialFormState);

    const [bins, setBins] = useState([]);
    const [priceList, setPriceList] = useState([]);
    const [formValues, setFormValues] = useState({});

    useEffect(() => {
        axios
            .get(APIConfig.DJANGO_API_BASE_URL + "/site-settings/")
            .then(response => {
                let data = camelcaseKeys(response.data, { deep: true });

              console.log('site settings data', data)

                console.log((data.smallBinPrice / 100).toFixed(2))

                let priceList = {
                    smallBinPrice: (data.smallBinPrice / 100).toFixed(2),
                    mediumBinPrice: (data.mediumBinPrice / 100).toFixed(2),
                    largeBinPrice: (data.largeBinPrice / 100).toFixed(2),

                    standardItemPrice: (data.standardItemPrice / 100).toFixed(2),
                    largeItemPrice: (data.largeItemPrice / 100).toFixed(2),
                    extraLargeItemPrice: (data.extraLargeItemPrice / 100).toFixed(2),
                    furniturePrice: (data.furniturePrice / 100).toFixed(2)
                };
                setPriceList(priceList);
            })
            .catch(error => {
                console.log(error);
            });
    }, []);


    return (
        <OrderContext.Provider value={{ binState: [bins, setBins], priceState: [priceList, setPriceList], formState: [formValues, setFormValues] }}>
            {children}
        </OrderContext.Provider>
    )
}

export default withRouter(OrderProvider);

