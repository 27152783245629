
import { MuiTheme, breakpoints } from 'assets/theme';

const ChevronCountPanelStyle = {
    container: {
        borderTop: `2px solid ${MuiTheme.palette.background.default}`,
        borderBottom: `2px solid ${MuiTheme.palette.background.default}`,
        height: 50
    },
    arrowContainer: {
        border: `2px solid ${MuiTheme.palette.background.default}`,
        height: 50,
        cursor: 'pointer',
        '&:hover': {
            background: MuiTheme.palette.background.default
        },
    },
    text: {
        fontFamily: ['Montserrat', 'Yantramanav', 'Roboto', 'Open Sans'],
        fontSize: '1.5rem'
    },
    [breakpoints.small]: {
        text: {
            fontSize: '1rem'
        }
    }
}

export default ChevronCountPanelStyle;
