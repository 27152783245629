import React, { useContext, useEffect, useState } from 'react';
import MessageIcon from '@material-ui/icons/Message'
import {
    Badge,
    Menu,
    MenuItem,
    Typography,
    withStyles
} from '@material-ui/core';
import NavBarMessageButtonStyle from './NavBarMessageButtonStyle';
import { UserContext } from '../../../Context/old-user';
import axios from 'axios';
import APIConfig from '../../../../settings';
import moment from 'moment';

const NavBarMessageButton = (props) => {
    const { classes } = props;
    const [unreadNotifications, setUnreadNotifications] = useState([]);
    const [anchorEl, setAnchorEl] = useState(null);
    const [readNotificationsSent, setReadNotificationsSent] = useState(false);

    let userData = useContext(UserContext);
    let user = null;
    if(userData) {
        user = userData.userData[0];
    }

    const readNotifications = () => {
        if(!readNotificationsSent) {
            let postData = [];
            for(let i in unreadNotifications) {
                postData.push(unreadNotifications[i].id);
            }

            axios.post(
              APIConfig.DJANGO_API_BASE_URL + '/users/me/notifications/read/',
              postData,
              { headers: { 'Authorization': 'Token ' + user.token } }
            )
              .then(response => {
                  setReadNotificationsSent(true);
              })
              .catch(error => {
                  console.log('error saving notification status');
                  console.error(error);
              });
        }
    };

    const handleClick = (e) => {
        if(unreadNotifications.length === 0) {
            return;
        }

        setAnchorEl(e.currentTarget);
        readNotifications();
    };

    const handleClose = () => {
        setAnchorEl(null);
    };

    const formatDateToLocale = (date) => {
        let UTCDate = moment.utc(date);
        let localDate = moment(UTCDate).local();

        return localDate.format('MM/DD/YYYY [at] hh:mm a')
    };

    useEffect(() => {
        let unreadNotifications = [];
        if(user !== null) {
            for(let i in user.notifications) {
                if(user.notifications[i].readStatus === 0) {
                    unreadNotifications.push(user.notifications[i]);
                    setUnreadNotifications(unreadNotifications);
                }
            }
        }
    }, [user]);

    if (user !== null && user.enableNotificationsWeb) {
        return (
            <>
                <Badge
                    badgeContent={unreadNotifications.length}
                    classes={{
                        badge: classes.badge
                    }}
                    className={classes.notificationButton}
                    aria-haspopup={"true"}
                    aria-controls={"notifications-menu"}
                    onClick={handleClick}
                >
                    <MessageIcon className={classes.notificationIcon}/>
                </Badge>
                <Menu
                    id={"notifications-menu"}
                    anchorEl={anchorEl}
                    open={Boolean(anchorEl) && unreadNotifications.length > 0}
                    onClose={handleClose}
                    getContentAnchorEl={null}
                    classes={{
                        list: classes.notificationOuterContainer
                    }}
                    anchorOrigin={{
                        vertical: 'bottom',
                        horizontal: 'right',
                    }}
                    transformOrigin={{
                        vertical: 'top',
                        horizontal: 'right',
                    }}
                >
                    {unreadNotifications.map((data, index) => (
                        <MenuItem key={data.id} className={classes.notificationContainer}>
                            <Typography className={classes.notificationDate}>
                                {formatDateToLocale(data.dateCreated)}
                            </Typography>
                            <Typography className={classes.notificationText} component={'p'} display={'block'}>
                                {data.body}
                            </Typography>
                        </MenuItem>
                    ))}
                </Menu>
            </>
        )
    }
    else {
        return null;
    }
};

export default withStyles(NavBarMessageButtonStyle)(NavBarMessageButton);
