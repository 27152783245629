import { MuiTheme, button, fontFamilyMontserrat } from 'assets/theme';

const NotificationCardStyle = {
    mainHeader: {
        width: '60%',
        minWidth: 190,
        minHeight: '70px',
        backgroundColor: MuiTheme.palette.secondary.main,
        padding: '20px 0 0 25px'
    },
    body: {
        border: '1px solid grey',
        padding: '0 20px',
        backgroundColor: MuiTheme.palette.common.white,
        minHeight: '420px'
    },
    submitButton: {
        ...button,
        borderRadius: '0',
        '&:hover': {
            background: MuiTheme.palette.secondary.dark
        },
        background: MuiTheme.palette.secondary.main,
        color: MuiTheme.palette.secondary.contrastText,
        ...fontFamilyMontserrat,
    },
    bodyText: {
        ...fontFamilyMontserrat,
        color: MuiTheme.palette.common.gray,
        fontSize: '17px',
        fontWeight: '400'
    },
    margin: {
        marginTop: '5%'
    }
}



export default NotificationCardStyle;
