const LoadingStyles = {
    root: {
        position: 'relative',
        width: '100%',
        height: '100%',
        zIndex: '10000',
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'center',
        background: 'white'
    },
};

export default LoadingStyles;
