import { breakpoints, fontFamilyMontserrat, MuiTheme } from 'assets/theme';

const CurrentOrderPaneStyle = {
  tableHeaderCell: {
    fontSize: '15pt',
    color: MuiTheme.palette.secondary.main,
  },
  tableBodyCell: {
    ...fontFamilyMontserrat,
    fontSize: '12pt',
    color: MuiTheme.palette.common.gray,
  },
  tableRowHeader: {
    background: MuiTheme.palette.common.black,
  },
  expandIconCell: {
    display: 'flex',
    justifyContent: 'flex-start',
    alignItems: 'center',

    '& > *': {
      color: '#878787'
    }
  },
  [breakpoints.small]: {
    tableHeaderCell: {
      fontSize: '12pt',
    },
    tableBodyCell: {
      fontSize: '10pt',
    },
  }
};


export default CurrentOrderPaneStyle;
