import { MuiTheme, breakpoints } from 'assets/theme';

const NavBarStyle = theme => ({
  root: {
    // flexGrow: 1,

    '& .MuiContainer-root': {
      paddingLeft: '10px'
    }
  },
  nav: {
    borderBottom: `2px solid ${MuiTheme.palette.secondary.main}`,
    boxShadow: 'none'
  },
  alignLeft: {
    flexGrow: 1,
    display: 'flex',
    justifyContent: 'flex-start',
    alignItems: 'center',
    flexDirection: 'row'
  },
  logo: {
    width: '100px',
    margin: '15px 0',
    marginRight: '25px',
    marginLeft: '10px'
  },
  accountButton: {
    '&:hover': {
      background: MuiTheme.palette.secondary.dark
    },
    background: MuiTheme.palette.secondary.main,
    borderRadius: '7px',
    width: '100%',
    maxWidth: '120px',
    color: MuiTheme.palette.secondary.contrastText,
    padding: '7px 0',
    WebkitBoxShadow: '1px 1px 3px 0px rgba(0,0,0,0.25)',
    MozBoxShadow: '1px 1px 3px 0px rgba(0,0,0,0.25)',
    boxShadow: '1px 1px 3px 0px rgba(0,0,0,0.25)'
  },
  menuButton: {
    display: 'none'
  },
  phoneNumberContainer: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "center",
  },
  tucsonPhoneNumberContainer: {
    paddingRight: "50px"
  },
  phoneNumber: {
    color:MuiTheme.palette.secondary.main,
    textDecoration: 'none',

    '&:hover': {
      color:MuiTheme.palette.secondary.main,
      textDecoration: 'none',
    }
  },
  cityName: {
    fontWeight: 600
  },
  navLinkContainer: {
    display: 'block'
  },
  activeLink: {
    '&:hover': {
      color: MuiTheme.palette.secondary.main
    },
    color: MuiTheme.palette.secondary.main,
    padding: '6px 25px',
    textDecoration: 'none'
  },
  navLink: {
    '&:hover': {
      color: MuiTheme.palette.secondary.main
    },
    color: MuiTheme.palette.common.gray,
    padding: '6px 25px',
    textDecoration: 'none'
  },
  drawer: {
    display: 'none'
  },
  drawerList: {
    width: 250
  },

  [breakpoints.medium]: {
    menuButton: {
      display: 'block'
    },
    drawer: {
      display: 'block'
    },
    accountButton: {
      display: 'none'
    },
    navLinkContainer: {
      display: 'none'
    }
  },

  '@media only screen and (max-width: 510px)': {
    phoneNumber: {
      display: 'none'
    },
    cityName: {
      display: 'none'
    },
  }
});

export default NavBarStyle;
