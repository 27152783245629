import React, { useContext, useState } from 'react';
import { Grid, Typography, withStyles, Switch, Button } from '@material-ui/core';
import { MuiTheme } from 'assets/theme';
import axios from 'axios';
import Cookies from 'universal-cookie';
import snakecaseKeys from 'snakecase-keys';

import APIConfig from 'settings';
import NotificationCardStyle from './NotificationCardStyle';
import { UserContext } from "context/user";
import SnackbarAlert from 'components/Common/Alert/Snackbar';

const NotificationCard = (props) => {
    // Accept userData as a prop
    const { classes, disabled = false, adminUserData } = props;
    const context = useContext(UserContext);
    // Use propUserData if provided, otherwise fallback to userData from context
    const userData = adminUserData || context.userData;
    const setUserData = context.setUserData;

    const [showAlert, setShowAlert] = useState({ open: false, severity: '', message: '' });

    const handleChange = name => event => {
        setUserData({ ...userData, [name]: event.target.checked });
    };

    const handleSubmit = (e) => {
        const cookies = new Cookies();
        const token = cookies.get('token');

        const body = {
            enableNotificationsWeb: userData.enableNotificationsWeb,
            enableNotificationsMobile: userData.enableNotificationsMobile,
            enableNotificationsEmail: userData.enableNotificationsEmail,
        }

        axios.patch(
          APIConfig.DJANGO_API_BASE_URL + '/api/users/me/', snakecaseKeys(body), {
              headers: {
                  "Authorization": "Token " + token
              }
          }).then((response => {
            setShowAlert({ open: true, severity: 'success', message: 'Saved notification data successfully' });

        })).catch(error => {
            setShowAlert({ open: true, severity: 'error', message: 'Error when saving notification data' });
        });
    }

    return (
      <Grid container direction="column" justify="space-evenly" className={classes.margin}>
          <Grid container alignItems="center">
              <Grid item className={classes.mainHeader}>
                  <Typography variant="h5">NOTIFICATIONS</Typography>
              </Grid>
          </Grid>
          <Grid container justify="center" alignItems="center" className={classes.body}>
              <Grid item xs={9} lg={9}>
                  <Typography variant="h6" className={classes.bodyText}>Email Notifications</Typography>
              </Grid>
              <Grid item xs={3} lg={3}>
                  <Switch
                    checked={userData.enableNotificationsEmail}
                    disabled={disabled}
                    onChange={!disabled ? handleChange('enableNotificationsEmail') : () => {}}
                    value="enableNotificationsEmail"
                    color="secondary"
                    inputProps={{ 'aria-label': 'primary checkbox' }}
                  />
              </Grid>
              <Grid item xs={9} lg={9}>
                  <Typography variant="h6" className={classes.bodyText}>Text Notifications</Typography>
              </Grid>
              <Grid item xs={3} lg={3}>
                  <Switch
                    checked={userData.enableNotificationsMobile}
                    disabled={disabled}
                    onChange={!disabled ? handleChange('enableNotificationsMobile') : () => {}}
                    value="enableNotificationsMobile"
                    color="secondary"
                    inputProps={{ 'aria-label': 'primary checkbox' }}
                  />
              </Grid>
              <Grid item xs={9} lg={9}>
                  <Typography variant="h6" className={classes.bodyText}>Web Site Notifications</Typography>
              </Grid>
              <Grid item xs={3} lg={3}>
                  <Switch
                    checked={userData.enableNotificationsWeb}
                    disabled={disabled}
                    onChange={!disabled ? handleChange('enableNotificationsWeb') : () => {}}
                    value="enableNotificationsWeb"
                    color="secondary"
                    inputProps={{ 'aria-label': 'primary checkbox' }}
                  />
              </Grid>
              <Grid item xs={12} lg={12}>
                  {!disabled && (
                    <Button className={classes.submitButton} onClick={() => handleSubmit()}>Submit Changes</Button>
                  )}
              </Grid>
          </Grid>
          <SnackbarAlert showAlert={showAlert} setShowAlert={setShowAlert} />
      </Grid>
    );
}

export default withStyles(NotificationCardStyle)(NotificationCard);
