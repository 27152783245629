import { MuiTheme, button } from 'assets/theme';
import { startAdornment } from 'assets/theme';

const EditMyInfoModalStyle = {
    modal: {
        position: 'absolute',
        width: '40%',
        minWidth: '300px',
        backgroundColor: MuiTheme.palette.common.white,
        padding: '30px 50px',
        borderRadius: '8px',
        top: `50%`,
        left: `50%`,
        overflowY: 'auto',
        height: '80%',
        display: 'block',
        margin: 'auto',
        transform: `translate(-50%, -50%)`,
        '-webkit-scrollbar': {
            display: 'none'
        }
    },
    gridContainer: {
        '&:first-of-type': {
            paddingLeft: '0 !important'
        },
        '&:last-of-type': {
            paddingRight: '0 !important'
        }
    },
    form: {
        '& .MuiTextField-root': {
            // margin: MuiTheme.spacing(1),
            width: '100%',
            textAlign: 'center'
        },
    },
    startAdornment: {
        ...startAdornment
    },
    buttonContainer: {
        height: '100px'
    },
    btnSubmit: {
        marginTop: '30px'
    },
    subtitle: {
      marginTop: '25px'
    },
};

export default EditMyInfoModalStyle;
