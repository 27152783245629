import React, {useContext, useEffect, useState} from 'react';
import { withStyles } from '@material-ui/core';
import Modal from '@material-ui/core/Modal';
import { Grid, Typography, Button } from '@material-ui/core';
import {
  injectStripe,
  CardNumberElement,
  CardCvcElement,
  CardExpiryElement
} from 'react-stripe-elements';
import LabelIcon from '@material-ui/icons/Label';
import PhoneAndroidIcon from '@material-ui/icons/PhoneAndroid';
import MailIcon from '@material-ui/icons/Mail';
import LockIcon from '@material-ui/icons/Lock';
import BusinessIcon from '@material-ui/icons/Business';
import CreditCardIcon from '@material-ui/icons/CreditCard';
import LocationOnIcon from '@material-ui/icons/LocationOn';
import DateRangeIcon from '@material-ui/icons/DateRange';
import HomeIcon from '@material-ui/icons/Home';
import LocationCityIcon from '@material-ui/icons/LocationCity';
import SnackbarAlert from 'components/Common/Alert/Snackbar';
import TextFieldWrapper from 'components/Common/Form/TextFieldWrapper';
import EditMyInfoModalStyle from './EditMyInfoModalStyle';
import { cleanObjectOfEmptyValues } from 'components/Common/Util/util';
import {Form, Formik} from "formik";

import { UserContext } from "context/user";
import TextField from "@material-ui/core/TextField";
import InputMask from "react-input-mask";
import StateSelectField from "components/Common/Form/StateSelectField";
import StripeField from "components/Common/Form/StripeField";
import djangoService from "services/djangoService";
import {handleFormErrors} from "services/errorHandlerService";
import * as Yup from "yup";

const EditMyInfoModal = (props) => {
  const { modalOpen, setModalOpen, handleClose, classes, stripe: { createToken } } = props;
  const [showAlert, setShowAlert] = useState({ open: false, severity: '', message: '' })
  const {userData, setUserData, setUserDataRaw} = useContext(UserContext);
  const [zipCodeData, setZipCodeData] = useState([]);
  const creditCardValidation = { cardNumber: false, cvc: false, expiration: false };

  const validationSchema = Yup.object().shape({
    firstName: Yup.string(),
    lastName: Yup.string(),
    phoneNumber: Yup.string()
    .min(17, "Phone number is not valid")
    .max(17, "Phone number is not valid"),
    email: Yup.string().email('Invalid email'),
    address: Yup.string(),
    postalCode: Yup.string().required('Postal code is required').test(
      'isValidZipCode',
      "We’re sorry, the zip code you entered is not part of our Service Area. For questions or to find out when " +
      "we may support this area soon, please email us at info@SendyStorage.com",
      (value) => zipCodeData.includes(value)
    ),
    apartment: Yup.string(),
    city: Yup.string(),
    state: Yup.string(),
    cardholderName: Yup.string(),
    currentPassword: Yup.string().test('matchValue', 'New password must be provided with current password',
      function () {
        // a normal function is important here and gives us access to "this" which
        // is how we get the value
        return (this.parent.currentPassword && this.parent.newPassword) || (!this.parent.currentPassword && !this.parent.newPassword);
      }
    ),
    newPassword: Yup.string().test('matchValue', 'New password must be provided with current password',
        function () {
          // a normal function is important here and gives us access to "this" which
          // is how we get the value
          return (this.parent.currentPassword && this.parent.newPassword) || (!this.parent.currentPassword && !this.parent.newPassword);
        }
      )
  });

  useEffect(() => {
    // set the valid zip codes
    if(userData) {
      console.log('edit my info modal user data', userData);

      djangoService.Order.getValidZipCodes(userData.city)
        .then(response => {
          let formattedZipData = [];
          for(let i of response.data) {
            formattedZipData.push(i.zipCode);
          }
          console.log('zipcode data', formattedZipData)
          setZipCodeData(formattedZipData);
        }).catch(error => {
        setShowAlert({
          open: true,
          severity: "error",
          message: "Error retrieving zip code data",
        });
      })
    }
  }, [userData])

  function checkObjectValueTrue(obj) {
    for (var o in obj)
      if (!obj[o]) return false;

    return true;
  }

  const checkforValidZipCode = (zipCode, city, setFieldError) => {
    console.log('check for valid zip code...', zipCode, city)

    djangoService.Order.getValidZipCodes(city)
      .then(response => {
        let formattedZipData = [];
        for(let i of response.data) {
          formattedZipData.push(i.zipCode);
        }
        console.log('zipcode data', formattedZipData)
        setZipCodeData(formattedZipData);
        if(!formattedZipData.includes(zipCode)) {
          setFieldError('postalCode', "We’re sorry, the zip code you entered is not part of our Service Area.");
        }
        else {
          setFieldError('postalCode', '');
        }
      })
      .catch(error => {
        setShowAlert({
          open: true,
          severity: "error",
          message: "Error retrieving zip code data",
        });
      })
  }

  const onSubmit = async (values, {setErrors, setFieldError}) => {
    let body = {
      ...values,
    };

    const result = await createToken({
      type: "card",
      name: values.cardholderName,
    });

    console.log('stripe result', result);

    if(result.token && !values.cardholderName) {
      // validate cardholder name manually due to stripe restrictions, it only matters if the token was created
      setFieldError('cardholderName', 'Card Holder Name is Required');
      return;
    }

    if(result.token) {
      console.log('token', result.token)
      body.stripeToken = result.token.id;
    }

    body = cleanObjectOfEmptyValues(body);

    console.log('body', body);

    djangoService.User.update(body)
      .then(response => {
        console.log('update user response success!', response);
        setUserDataRaw(response.data);
        setModalOpen(false);
        setShowAlert({ open: true, severity: 'success', message: 'Saved information successfully' });
      })
      .catch(error => handleFormErrors(error, setErrors));
  };

  return (

    <>
      <Modal
        aria-labelledby="simple-modal-title"
        aria-describedby="simple-modal-description"
        open={modalOpen}
        onClose={handleClose}
      >
        <div>
          <Grid container className={classes.modal}
          >
            <Formik
              initialValues={{
                firstName: userData.firstName,
                lastName: userData.lastName,
                phoneNumber: userData.phoneNumber,
                email: userData.email,
                address: userData.address,
                postalCode: userData.postalCode,
                apartment: userData.apartment,
                city: userData.city,
                state: userData.state,
                cardholderName: '',
                currentPassword: '',
                newPassword: ''
              }}
              validationSchema={validationSchema}
              validateOnChange={false}
              enableReinitialize
              onSubmit={onSubmit}
            >
              {({
                  values,
                  errors,
                  handleChange,
                  setFieldValue,
                  setFieldError
                }) => (
                <Form>
                  <Grid container justify="center">
                    {/* MY INFO FORM */}
                    <Grid container item xs={12} justify="center">
                      <Typography variant="h6" className={classes.subtitle}><strong>MY INFO</strong></Typography>
                    </Grid>
                    <Grid container item xs={12} spacing={2}>
                      <Grid item xs={12} md={6} className={classes.gridContainer}>
                        <TextField
                          type="text"
                          name="firstName"
                          label="First Name"
                          fullWidth
                          margin="normal"
                          InputProps={{
                            startAdornment: <LabelIcon className={classes.startAdornment} />
                          }}
                          inputProps={{
                            min: 0,
                            style: { textAlign: 'left' },
                          }}
                          value={values.firstName}
                          error={!!errors.firstName}
                          helperText={errors.firstName}
                          onChange={handleChange}
                        />
                      </Grid>

                      <Grid item xs={12} md={6} className={classes.gridContainer}>
                        <TextField
                          type="text"
                          name="lastName"
                          label="Last Name"
                          fullWidth
                          margin="normal"
                          InputProps={{
                            startAdornment: <LabelIcon className={classes.startAdornment} />
                          }}
                          inputProps={{
                            min: 0,
                            style: { textAlign: 'left' },
                          }}
                          value={values.lastName}
                          error={!!errors.lastName}
                          helperText={errors.lastName}
                          onChange={handleChange}
                        />
                      </Grid>
                    </Grid>

                    <Grid container item xs={12} spacing={2}>
                      <InputMask
                        mask={'+1 (999) 999-9999'}
                        onChange={handleChange}
                        value={values.phoneNumber}
                      >
                        {() => (
                          <TextField
                            fullWidth
                            name="phoneNumber"
                            classes={{
                              root: classes.textField
                            }}
                            label="Phone Number"
                            margin="normal"
                            type="text"
                            InputProps={{
                              startAdornment: (
                                <PhoneAndroidIcon className={classes.startAdornment} />
                              )
                            }}
                            error={!!errors.phoneNumber}
                            helperText={errors.phoneNumber}
                            onChange={handleChange}
                          />
                        )}
                      </InputMask>
                    </Grid>

                    <Grid container item xs={12} spacing={2}>
                      <TextField
                        type="email"
                        name="email"
                        label="Email Address"
                        fullWidth
                        margin="normal"
                        InputProps={{
                          startAdornment: <MailIcon className={classes.startAdornment} />
                        }}
                        inputProps={{
                          min: 0,
                          style: { textAlign: 'left' },
                        }}
                        value={values.email}
                        error={!!errors.email}
                        helperText={errors.email}
                        onChange={handleChange}
                      />
                    </Grid>

                    <Grid container item xs={12} spacing={2}>
                      <TextField
                        type="password"
                        name="currentPassword"
                        label="Current Password"
                        fullWidth
                        margin="normal"
                        autoComplete={"new-password"}
                        inputProps={{
                          min: 0,
                          style: { textAlign: 'left' },
                        }}
                        value={values.currentPassword}
                        error={!!errors.currentPassword}
                        helperText={errors.currentPassword}
                        onChange={handleChange}
                      />
                    </Grid>

                    <Grid container item xs={12} spacing={2}>
                      <TextField
                        type="password"
                        name="newPassword"
                        label="New Password"
                        fullWidth
                        margin="normal"
                        inputProps={{
                          min: 0,
                          style: { textAlign: 'left' },
                        }}
                        value={values.newPassword}
                        error={!!errors.newPassword}
                        helperText={errors.newPassword}
                        onChange={handleChange}
                      />
                    </Grid>
                    {/* -- MY INFO FORM */}

                    {/* DELIVERY FORM */}
                    <Grid container item xs={12} justify="center" className={classes.subtitle}>
                      <Typography variant="h6"><strong>DELIVERY</strong></Typography>
                    </Grid>
                    <Grid container item xs={12} spacing={2}>
                      <TextField
                        type="text"
                        name="address"
                        label="Address"
                        fullWidth
                        margin="normal"
                        InputProps={{
                          startAdornment: <HomeIcon className={classes.startAdornment} />
                        }}
                        inputProps={{
                          min: 0,
                          style: { textAlign: 'left' },
                        }}
                        value={values.address}
                        error={!!errors.address}
                        helperText={errors.address}
                        onChange={handleChange}
                      />
                    </Grid>

                    <Grid container item xs={12} spacing={2}>
                      <Grid item xs={12} md={6} className={classes.gridContainer}>
                        <TextField
                          type="text"
                          name="postalCode"
                          label="Postal Code"
                          fullWidth
                          margin="normal"
                          InputProps={{
                            startAdornment: <LocationOnIcon className={classes.startAdornment} />
                          }}
                          inputProps={{
                            min: 0,
                            style: { textAlign: 'left' },
                          }}
                          value={values.postalCode}
                          error={!!errors.postalCode}
                          helperText={errors.postalCode}
                          onChange={handleChange}
                          onBlur={() => checkforValidZipCode(values.postalCode, values.city, setFieldError)}
                        />
                      </Grid>
                      <Grid item xs={12} md={6} className={classes.gridContainer}>
                        <TextField
                          type="text"
                          name="apartment"
                          label="Apartment"
                          fullWidth
                          margin="normal"
                          InputProps={{
                            startAdornment: <BusinessIcon className={classes.startAdornment} />
                          }}
                          inputProps={{
                            min: 0,
                            style: { textAlign: 'left' },
                          }}
                          value={values.apartment}
                          error={!!errors.apartment}
                          helperText={errors.apartment}
                          onChange={handleChange}
                        />
                      </Grid>
                    </Grid>

                    <Grid container item xs={12} spacing={2}>
                      <Grid item xs={12} md={6} className={classes.gridContainer}>
                        <TextField
                          type="text"
                          name="city"
                          label="City"
                          fullWidth
                          margin="normal"
                          InputProps={{
                            startAdornment: <LocationCityIcon className={classes.startAdornment} />
                          }}
                          inputProps={{
                            min: 0,
                            style: { textAlign: 'left' },
                          }}
                          value={values.city}
                          error={!!errors.city}
                          helperText={errors.city}
                          onChange={handleChange}
                          onBlur={() => checkforValidZipCode(values.postalCode, values.city, setFieldError)}
                        />
                      </Grid>
                      <Grid item xs={12} md={6} className={classes.gridContainer}>
                        <StateSelectField
                          type="text"
                          name="state"
                          label="State"
                          fullWidth
                          margin="normal"
                          inputProps={{
                            min: 0,
                          }}
                          value={values.state}
                          error={!!errors.state}
                          helperText={errors.state}
                          onChange={handleChange}
                        />
                      </Grid>
                    </Grid>
                    {/* -- DELIVERY FORM */}

                    {/* MY BILLING FORM */}
                    <Grid container item xs={12} justify="center">
                      <Typography variant="h6" className={classes.subtitle}><strong>BILLING</strong></Typography>
                    </Grid>

                    <Grid container item xs={12} spacing={2}>
                      <StripeField
                        type="text"
                        name="cardNumber"
                        label="Credit Card Number"
                        fullWidth
                        margin="normal"
                        InputProps={{
                          startAdornment: <CreditCardIcon className={classes.startAdornment} />
                        }}
                        inputProps={{
                          min: 0,
                          style: { textAlign: 'left' },
                        }}
                        stripeComponent={CardNumberElement}
                        setFieldError={setFieldError}
                        setFieldValue={setFieldValue}
                      />
                    </Grid>

                    <Grid container item xs={12} spacing={2}>
                      <TextField
                        type="text"
                        name="cardholderName"
                        label="Card Holder Name"
                        fullWidth
                        margin="normal"
                        placeholder={'John Doe'}
                        InputProps={{
                          startAdornment: <LabelIcon className={classes.startAdornment} />
                        }}
                        inputProps={{
                          min: 0,
                          style: { textAlign: 'left' },
                        }}
                        value={values.cardholderName}
                        error={!!errors.cardholderName}
                        helperText={errors.cardholderName}
                        onChange={handleChange}
                      />
                    </Grid>

                    <Grid container item xs={12} spacing={2}>
                      <Grid item xs={12} md={6} className={classes.gridContainer}>
                        <StripeField
                          type="text"
                          name="cvc"
                          label="CVC"
                          fullWidth
                          margin="normal"
                          InputProps={{
                            startAdornment: <LockIcon className={classes.startAdornment} />
                          }}
                          inputProps={{
                            min: 0,
                            style: { textAlign: 'left' },
                          }}
                          stripeComponent={CardCvcElement}
                          setFieldError={setFieldError}
                          setFieldValue={setFieldValue}
                        />
                      </Grid>
                      <Grid item xs={12} md={6} className={classes.gridContainer}>
                        <StripeField
                          type="text"
                          name="expiration"
                          label="Expiration"
                          fullWidth
                          margin="normal"
                          InputProps={{
                            startAdornment: <DateRangeIcon className={classes.startAdornment} />
                          }}
                          inputProps={{
                            min: 0,
                            style: { textAlign: 'left' },
                          }}
                          stripeComponent={CardExpiryElement}
                          setFieldError={setFieldError}
                          setFieldValue={setFieldValue}
                        />
                      </Grid>
                    </Grid>
                    {/* -- MY BILLING FORM */}
                  </Grid>
                  <Grid container className={classes.buttonContainer} direction="column" justify="center"
                        alignItems="center">
                    <Grid item align="center">
                      <Button
                        className={classes.btnSubmit}
                        type={'submit'}
                        size="large"
                        variant="contained"
                        color="secondary"
                      >
                        Submit Changes
                      </Button>
                    </Grid>
                  </Grid>
                </Form>
              )}
            </Formik>
          </Grid>
        </div>
      </Modal>
      <SnackbarAlert showAlert={showAlert} setShowAlert={setShowAlert} />
    </>
  );
}

export default withStyles(EditMyInfoModalStyle)(injectStripe(EditMyInfoModal));
