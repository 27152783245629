import { format, parseISO } from 'date-fns';
import moment from 'moment';

// lodash-es is a tree-shakable version of lodash
import { uniq, difference, isEmpty } from 'lodash-es'

export const GetIsoDateAndTime = (date, time) => {
  var isoDate = new Date(
    format(new Date(date), 'yyyy-MM-dd') + ' ' + time
  ).toISOString().split('T');
  return { isoDate: isoDate[0], isoTime: isoDate[1] };
};

export const GetParsedDateAndTime = (date, time) => {
  var dateas = '';
  if (typeof date === 'object') {
    dateas = format(new Date(format(date, 'yyyy-MM-dd') + 'T' + time), 'yyyy-MM-dd HH:mm:ss');
  }
  else {
    dateas = format(new Date(date + 'T' + time), 'yyyy-MM-dd HH:mm:ss');

  }
  return dateas.replace(' ', 'T');
};

export const cleanObjectOfEmptyValues = (obj) => {
  let validObj = {};
  for (let i of Object.keys(obj)) {
    // we dont use if(obj[i]) because 0 is valid
    if (obj[i] !== "" && obj[i] !== undefined && obj[i] !== null) {
      validObj[i] = obj[i]
    }
  }
  return validObj;
};


export const scrollByContainerId = id => {
  var elmnt = document.getElementById(id);
  elmnt.scrollIntoView({ behavior: "smooth" });
}

export function formatDate(d) {
  var date = new Date(d);

  if (isNaN(date.getTime())) {
    return d;
  }
  else {

    var month = new Array();
    month[0] = "Jan";
    month[1] = "Feb";
    month[2] = "Mar";
    month[3] = "Apr";
    month[4] = "May";
    month[5] = "Jun";
    month[6] = "Jul";
    month[7] = "Aug";
    month[8] = "Sept";
    month[9] = "Oct";
    month[10] = "Nov";
    month[11] = "Dec";

    let day = date.getDate();

    if (day < 10) {
      day = "0" + day;
    }

    return day + " " + month[date.getMonth()] + " " + date.getFullYear();
  }

}

export function formatReadableDateTime(isoString) {
  let d = moment(isoString);
  return d.format('hh:mma on MM/DD/YYYY')
}

export function formatReadableDate(isoString) {
  let d = moment(isoString);
  return d.format('MM/DD/YYYY')
}

export const chunkArray = (arr, chunkSize) => {
  let R = [];
  for (let i = 0, len = arr.length; i < len; i += chunkSize)
    R.push(arr.slice(i, i + chunkSize));
  return R;
};

export const getOrdinalNum = (n) => {
  return n + (n > 0 ? ['th', 'st', 'nd', 'rd'][(n > 3 && n < 21) || n % 10 > 3 ? 0 : n % 10] : '');
};


export const getErrorsByInputFields = (fields, error) => {
  var errorArr = [];
  // error = { postal_code: 'asdasds' }
  fields.forEach(field => {
    if (error.hasOwnProperty(field)) {
      errorArr.push(`"${titleCase(field.replace('_', ' '))}": ${error[field]}`)
    }
  })
  return errorArr;
}

function titleCase(str) {
  var splitStr = str.toLowerCase().split(' ');
  for (var i = 0; i < splitStr.length; i++) {
    splitStr[i] = splitStr[i].charAt(0).toUpperCase() + splitStr[i].substring(1);
  }
  return splitStr.join(' ');
}

export const updateBinItemStatus = (orders, bins, items, status, readableStatus) => {
  let newOrders = [...orders];
  for (let order of newOrders) {
    for (let bin of order.bins) {
      if (bins.includes(bin.id)) {
        bin.status = status;
        bin.readableStatus = readableStatus;
      }
    }

    for (let item of order.items) {
      if (items.includes(item.id)) {
        item.status = status;
        item.readableStatus = readableStatus;
      }
    }
  }
  return newOrders;
}

export const formatMyInfoAddress = (myInfo) => {
  try {
    const {
      address,
      city,
      state,
      postalCode,
      postal_code, // add postal_code to the destructuring as a fallback
      apartment = ''
    } = myInfo;

    // determine which postal code to use
    const effectivePostalCode = postalCode || postal_code;

    if (address && city && state && effectivePostalCode) {
      // use effectivePostalCode which is either postalCode or postal_code
      return `${address} ${apartment} ${city} ${state} ${effectivePostalCode}`;
    } else {
      return 'No Address set';
    }
  } catch (e) {
    console.error('An error occurred formatting address', myInfo);
    return 'No Address set';
  }
};

export const shouldDisableDate = (date) => {
  let today = new Date();
  let tomorrow = new Date(today);
  tomorrow.setDate(tomorrow.getDate() + 2);
  tomorrow.setHours(0, 0, 0, 0);
  return date < tomorrow;
};

export const convertDateToUTC = (date) => {
  return new Date(
    date.getUTCFullYear(),
    date.getUTCMonth(),
    date.getUTCDate(),
    date.getUTCHours(),
    date.getUTCMinutes(),
    date.getUTCSeconds()
  );
};

export const isSame = (_arr1, _arr2) => {

  if (!Array.isArray(_arr1) || ! Array.isArray(_arr2) || _arr1.length !== _arr2.length)
    return false;

  var arr1 = _arr1.concat().sort();
  var arr2 = _arr2.concat().sort();

  for (var i = 0; i < arr1.length; i++) {

    if (arr1[i] !== arr2[i])
      return false;

  }

  return true;

}
