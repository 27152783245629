import React, { useEffect, useState } from 'react';
import {
    withStyles,
    CircularProgress
} from '@material-ui/core';
import LoadingStyles from './LoadingStyles';

const Loading = (props) => {
    const { classes } = props;

    const [show, setShow] = useState(props.show);

    useEffect(() => {
        console.log('loading test')
        setShow(props.show);
    }, [props.show]);

    if(!show) {
        return null;
    }

    return (
        <div className={classes.root}>
            <CircularProgress color={'secondary'}>

            </CircularProgress>
        </div>
    )
};

export default withStyles(LoadingStyles)(Loading);
