//special variable provided by MaterialUI to override their default colors
//we use this in index.js
const MuiTheme = {
  palette: {
    common: {
      black: '#000',
      white: '#fff',
      gray: '#878787'
    },
    background: {
      paper: '#FFF',
      default: '#F3F3F3'
    },
    primary: {
      main: '#000',
      hover: '#323232',
      contrastText: '#FFF'
    },
    secondary: {
      main: '#FFE001',
      dark: '#E7CA01',
      hover: '#E7CA01',
      contrastText: '#000'
    },
    error: {
      light: '#e57373',
      main: 'rgba(245, 43, 7, 1)',
      dark: '#d32f2f',
      contrastText: '#fff'
    },
    text: {
      primary: '#050505',
      secondary: '#878787',
      disabled: '#878787',
      hint: '#878787'
    },
    card: {
      divider: '#d5d5d5'
    }
  },
  typography: {
    fontFamily: ['Yantramanav', 'Montserrat', 'Roboto', 'Open Sans']
  }
};

//breakpoints
const breakpoints = {
  // the below weird comments are jsx doc strings,
  // hit Ctrl + Q to get a note

  /** 600px or less */
  extraSmall: '@media only screen and (max-width: 600px)',
  /** 768px or less */
  small: '@media only screen and (max-width: 768px)',
  /** 992px or less */
  medium: '@media only screen and (max-width: 992px)',
  /** 1200px or less */
  large: '@media only screen and (max-width: 1200px)'
};

const startAdornment = {
  color: '#696969',
  marginRight: '10px'
};

const textField = {
  fontSize: '20px !important'
};

const appBarHeight = '120px';

const root = {
  margin: '0px',
  padding: '0px',
  height: `calc(100vh - ${appBarHeight})`,
  overflow: 'auto',
  width: '100%'
};

const pseudoLink = {
  textDecoration: 'underline',
  cursor: 'pointer'
};

const button = {
  justifyContent: 'center',
  '&:hover': {
    background: MuiTheme.palette.secondary.dark
  },
  background: MuiTheme.palette.secondary.main,
  fontSize: '20px',
  borderRadius: '7px',
  width: '100%',
  maxWidth: '300px',
  color: MuiTheme.palette.secondary.contrastText,
  padding: '8px 12px',
  margin: '20px 0',
  WebkitBoxShadow: '1px 1px 3px 0px rgba(0,0,0,0.25)',
  MozBoxShadow: '1px 1px 3px 0px rgba(0,0,0,0.25)',
  boxShadow: '1px 1px 3px 0px rgba(0,0,0,0.25)',
  textTransform: 'none'

};

const nextButton = {
  padding: '10px 35px',
  fontSize: '1.2rem',
  maxWidth: '100px !important'
};

const fontFamilyMontserrat = {
  fontFamily: ['Montserrat', 'Yantramanav', 'Roboto', 'Open Sans']
};

const cardShadow = {
  WebkitBoxShadow: "2px 2px 3px 0px rgba(0,0,0,0.3)",
  MozBoxShadow: "2px 2px 3px 0px rgba(0,0,0,0.3)",
  boxShadow: "2px 2px 3px 0px rgba(0,0,0,0.3)"
};

const pseudoBgImage = {
  position: 'absolute',
  width: '100%',
  height: '100%',
  top: 0,
  zIndex: -1,
  objectFit: 'cover'
}


const navRouteMarginTop = '75px';

export {
  root,
  MuiTheme,
  breakpoints,
  startAdornment,
  nextButton,
  textField,
  pseudoLink,
  pseudoBgImage,
  button,
  fontFamilyMontserrat,
  cardShadow,
  navRouteMarginTop
};
