const BIN_ITEM_STATUSES = {
  EMPTY_WITH_CUSTOMER: 0,
  IN_TRANSIT_TO_WAREHOUSE_FOR_STORAGE: 1,
  IN_STORAGE: 2,
  IN_TRANSIT_TO_CUSTOMER: 3,
  READY_FOR_USE: 4,
  RENTAL_WITH_CUSTOMER: 5,
  IN_TRANSIT_TO_WAREHOUSE_EMPTY: 6,
  WAITING_FOR_PICKUP: 7,
  PICKUP_SCHEDULED: 8,
  DROPOFF_SCHEDULED: 9,
  RETURNED_TO_CUSTOMER: 10
};

export default BIN_ITEM_STATUSES;
