import { MuiTheme } from 'assets/theme';

const OrdersCardStyle = {
    headerContainer: {
        maxWidth: '95%',
        display: 'contents'
    },
    enabledButton: {
        width: '25%',
        minWidth: '170px',
        height: '70px',
        backgroundColor: MuiTheme.palette.secondary.main,
        padding: '12px',
        color: 'black',
        cursor: 'pointer',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',

        '& > *': {
            textAlign: 'center'
        }
    },
    disabledButton: {
        width: '25%',
        minWidth: '170px',
        height: '70px',
        backgroundColor: '#E4E4E4',
        padding: '12px',
        color: '#A6A6A6',
        cursor: 'pointer',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',

        '& > *': {
            textAlign: 'center'
        }
    },
    margin: {
        marginTop: '5%'
    },
    paginationContainer: {
        background: 'white',
        width: '100%',
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'center',
        alignItems: 'center',
        padding: '10px 0'
    },
    currentTotal: {
        width: '100%',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        marginTop: '30px',
        textAlign: 'center'
    }
};

export default OrdersCardStyle
