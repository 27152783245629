import camelcaseKeys from 'camelcase-keys';

export const handleFormErrors = (error, setFormErrors) => {
  /**
   * A function to handle and set form errors
   */
  if (error.response) {
    /*
    * The request was made and the server responded with a
    * status code that falls out of the range of 2xx
    */
    console.log(error.response.data);
    console.log(error.response.status);
    console.log(error.response.headers);

    let formattedErrors = {};
    let camelcaseError = camelcaseKeys(error.response.data,{deep: true});

    if('data' in error.response) {
      for (let key of Object.keys(camelcaseError)) {
        let currentErr = camelcaseError[key];
        if (Array.isArray(currentErr)) {
          // convert to a string by taking the first element
          formattedErrors[key] = currentErr[0]
        }
        else if (typeof currentErr === 'string') {
          formattedErrors[key] = currentErr;
        }
      }
    }

    setFormErrors(formattedErrors);
  }
  else if (error.request) {
    /*
    * The request was made but no response was received, `error.request`
    * is an instance of XMLHttpRequest in the browser and an instance
    * of http.ClientRequest in Node.js
    */
    console.log(error.request);
    setFormErrors({'nonFieldErrors': 'An error occurred making a request from the server. Please try again later.'});
  }
  else {
    // Something happened in setting up the request and triggered an Error
    console.log('Error', error.message);
    setFormErrors({'nonFieldErrors': 'An error occurred making a request from the server. Please try again later.'});
  }
  console.log(error.config);
};
