import React  from 'react';

//material-ui core
import CircularProgress from '@material-ui/core/CircularProgress';
import { makeStyles } from '@material-ui/core';

const useStyles = makeStyles({
    root: {
        height: '100vh',
        width: '100%',
        position: 'absolute',
        top: 0,
        left: 0,
        background: '#d2d2d2',
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'center'
    }
});

const LoadingScreen = (props) => {
    const classes = useStyles();

    return (
        <div className={classes.root}>
            <CircularProgress size={70} color={'primary'}/>
        </div>
    );
};

export default LoadingScreen;
