const FooterModalStyle = {
  modal: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center'
  },
  card: {
    width: '65%',
    minWidth: '320px',
    maxWidth: '800px',
    height: '85%',
    position: 'relative',
    boxSizing: 'content-box',
    padding: '20px',
    textAlign: 'center'
  },
  closeIcon: {
    position: 'absolute',
    top: '20px',
    right: '20px',
    cursor: 'pointer'
  },
  pdfViewer: {
    margin: '0 auto',
    display: 'flex',
    minWidth: '300px',
    flexDirection: 'column',
    justifyContent: 'flex-start',
    alignItems: 'center',
  },
  pdfCanvas: {
    height: '85%',
    overflowY: 'auto',
    border: 'solid 1px black',
    width: '95%',
    minWidth: '300px',
    margin: 'auto',
    marginTop: '30px',

    '& canvas': {
      minWidth: '500px',
      width: '100% !important',
      position: 'absolute',
      left: 0,
    }
  },
  paginationPanel: {
    width: '100%',
    margin: '15px',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center'
  }
};

export default FooterModalStyle;
