import React, {useContext, useEffect, useLayoutEffect, useState} from 'react';

import {
  Route,
} from 'react-router-dom';
import { UserContext } from 'context/user';
import Loading from "components/Common/Loading/Loading";

const ProtectedRoute = props => {
  const { checkForUserData, userData } = useContext(UserContext);
  const [loading, setLoading] = useState(true)

  useEffect(() => {
    checkForUserData().then(() => {
      console.log('DONE LOADING');
      setLoading(false);
    })
  }, []);

  if(loading || !userData) {
    return <Loading show/>
  }
  else {
    return (
      <Route {...props}>
        {props.children}
      </Route>
    );
  }
};

export default ProtectedRoute;
