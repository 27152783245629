import React, { createContext, useState, useLayoutEffect, useEffect } from 'react';
import Cookies from 'universal-cookie';
import axios from 'axios';
import APIConfig from '../../settings';
import LoadingScreen from '../Layout/LoadingScreen/LoadingScreen';
import { Redirect, withRouter } from 'react-router-dom';
import camelcaseKeys from 'camelcase-keys';

export const UserContext = createContext();

export const UserProviderOld = ({ children }) => {
    const [userData, setUserData] = useState(null);
    const [selectedBins, setSelectedBins] = useState([]);
    const [selectedItems, setSelectedItems] = useState([]);

    // useEffect(() => {
    //     const cookies = new Cookies();
    //     const token = cookies.get('token');
    //     console.log('user provider auth', APIConfig.DJANGO_API_BASE_URL + '/api/users/me/')
    //
    //     axios.get(APIConfig.DJANGO_API_BASE_URL + '/api/users/me/', {
    //         headers: {
    //             'Authorization': 'Token ' + token
    //         }
    //     }).then(response => {
    //         if ('data' in response) {
    //             console.log('user data', response.data)
    //             response.data.token = token;
    //             setUserData(camelcaseKeys(response.data, { deep: true }));
    //             cookies.set('initialPickUp', { address: response.data.address, apartment: response.data.apartment })
    //         }
    //         else {
    //             console.log('auth failed')
    //             setUserData(false);
    //         }
    //     }).catch((error) => {
    //         console.log(error);
    //         console.log('auth failed')
    //         setUserData(false);
    //     });
    // }, []);

    return (
        <UserContext.Provider value={{
            userData: [userData, setUserData],
            selectedBins: [selectedBins, setSelectedBins],
            selectedItems: [selectedItems, setSelectedItems]
        }}>
            {/*{userData !== null ?*/}
            {/*    (*/}
            {/*        // false means the auth failed*/}
            {/*        userData !== false ?*/}
            {/*            (children)*/}
            {/*            :*/}
            {/*            (<Redirect to={"/login"} />)*/}
            {/*    )*/}
            {/*    :*/}
            {/*    (<LoadingScreen />)*/}
            {/*}*/}
        </UserContext.Provider>
    )
};

export default withRouter(UserProviderOld);
