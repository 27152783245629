const NavBarMessageButtonStyle = {
    notificationButton: {
        width: '35px',
        height: '35px',
        background: '#878787',
        marginLeft: '18px',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        cursor: 'pointer',
    },
    notificationOuterContainer: {
      maxHeight: '350px',
      overflowY: 'auto'
    },
    notificationContainer: {
        flexDirection: 'column',
        justifyContent: 'flex-start',
        alignItems: 'flex-start',
        cursor: 'default',
        borderBottom: 'solid 1px #000',
        maxWidth: '320px',
        margin: '0',

        '& *': {
          maxWidth: '320px'
        },

        '& p': {
            wordWrap: 'break-word',
            whiteSpace: 'normal'
        },

        '&:last-child': {
            borderBottom: 'none'
        }
    },
    notificationIcon: {
        color: '#121212',
    },
    notificationDate: {
        color: '#878787',
        display: 'block',
        fontSize: '14px',
        fontFamily: 'Yantramanav, Roboto, sans-serif'
    },
    notificationText: {
      fontWeight: '500',
      fontSize: '16px'
    },
    badge: {
        background: '#E31409',
        color: 'white',
    },
};

export default NavBarMessageButtonStyle;
