import React, {useEffect} from 'react';

import PropTypes from 'prop-types';

import {DatePicker, MuiPickersUtilsProvider} from "@material-ui/pickers";
import MomentUtils from "@date-io/moment";
import moment from "moment-timezone";
import {isFunction} from "utils";

const DatePickerField = props => {
  const {
    minimumDate,
  } = props;

  const momentDateFormat = 'MMMM Do, YYYY'

  const getMinimumDate = () => {
    let calculatedMinDate = moment();
    calculatedMinDate.add(2, 'days');

    if(minimumDate && minimumDate > calculatedMinDate) {
      return minimumDate;
    }
    else {
      return calculatedMinDate
    }
  }

  useEffect(() => {
    console.log('date picker minimum date', minimumDate)
  }, [minimumDate])

  const onChange = (date) => {
    let minDate = getMinimumDate();

    if(date < getMinimumDate()) {
      date = minDate;
    }

    let isoDate = moment(date).format().split('T')[0];
    let formattedDate = moment(date).format(momentDateFormat);

    if(isFunction(props.onChange)) {
      props.onChange(isoDate, formattedDate)
    }
  }

  const shouldDisableDate = (date) => {
    let minimumDate = getMinimumDate();

    //let today = new Date();
    // let tomorrow = new Date(today);
    // tomorrow.setDate(tomorrow.getDate() + 2);
    // tomorrow.setHours(0, 0, 0, 0);
    return date < minimumDate;
  };

  return (
    <MuiPickersUtilsProvider utils={MomentUtils}>
      <DatePicker
        {...props}
        inputVariant="outlined"
        format='MMMM Do, YYYY'
        disablePast
        initialFocusedDate={null}

        shouldDisableDate={shouldDisableDate}
        onChange={onChange}
      />
    </MuiPickersUtilsProvider>
  );
};

DatePickerField.propTypes = {
  classes: PropTypes.object
};

export default DatePickerField;
