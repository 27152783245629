import { MuiTheme, button } from 'assets/theme';
import Backdrop from '@material-ui/core/Backdrop';
import Fade from '@material-ui/core/Fade';

const PickUpModalStyle = {
  modal: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },

  paperContainer: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'flex',
    textAlign: 'center',
    margin: '0 auto',
  },

  fullPickupText: {
    width: '320px',
    textAlign: 'center',
    margin: '0 auto',
    marginBottom: '10px',
  },

  closeButtonContainer: {
    position: 'absolute',
    top: '0px',
    right: '0px',
    cursor: 'pointer'
  },

  inputLabel: {
    background: 'white',
    padding: '4px',
  },

  paperStyle: {
    minWidth: '440px',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    fontFamily: MuiTheme.typography.fontFamily[2],
    padding: '30px'
  },

  title: {
    fontSize: '2em',
  },

  numberItems: {
    color: '#B8B8B8',
    margin: '10px 0'
  },
  address: {
    marginTop: '2px'
  },

  selectionContainer: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'space-between',
    textAlign: 'center',
    margin: '0 auto',
    marginTop: '30px',
    width: '320px'
  },

  formControl: {
    width: '220px',

    '& *': {
      textAlign: 'left'
    }
  },

  errorText: {
    color: MuiTheme.palette.error.main,
    width: '90%',
    margin: 'auto',
    wordWrap: 'break-word',
    marginTop: '20px'
  },

  submitButton: {
    ...button,
    maxWidth: '250px',
    margin: '0',
    height: '45px',
    padding: '7px 20px',
    marginBottom: '50px',
    borderRadius: '0',
    '&:hover': {
      background: MuiTheme.palette.secondary.dark
    },
    background: MuiTheme.palette.secondary.main,
    color: MuiTheme.palette.secondary.contrastText,
  },

};

export default PickUpModalStyle
