import React, { useState, useContext, useEffect } from 'react';
import { Grid, Typography, withStyles } from '@material-ui/core';
import { StripeProvider, Elements } from 'react-stripe-elements';

import MyInfoCardStyle from './MyInfoCardStyle';
import EditMyInfoModal from 'components/MyAccount/EditMyInfoModal/EditMyInfoModal';
import APIConfig from 'settings';
import { formatMyInfoAddress } from 'components/Common/Util/util';
import { UserContext } from "context/user";

const MyInfoCard = (props) => {
  const { classes, userFranchise, disabled = false, adminUserData } = props;
  // use adminUserData if provided, otherwise fallback to userData from context
  const contextUserData = useContext(UserContext).userData;
  const userData = adminUserData || contextUserData;

  const [modalOpen, setModalOpen] = useState(false);
  const [formattedAddress, setFormattedAddress] = useState('');
  const [stripeKey, setStripeKey] = useState(APIConfig.STRIPE_PUBLIC_KEY_TUCSON);

  const handleOpen = () => {
    setModalOpen(true);
  };

  const handleClose = () => {
    setModalOpen(false);
  };

  useEffect(() => {
    console.log('address changed', formatMyInfoAddress(userData));
    setFormattedAddress(formatMyInfoAddress(userData));
  }, [userData]);

  useEffect(() => {
    if (userFranchise.toLowerCase() === 'tucson') {
      console.log('stripe key set to tucson');
      setStripeKey(APIConfig.STRIPE_PUBLIC_KEY_TUCSON);
    } else if (userFranchise.toLowerCase() === 'austin') {
      console.log('stripe key set to austin');
      setStripeKey(APIConfig.STRIPE_PUBLIC_KEY_AUSTIN);
    }
  }, [userFranchise]);

  const editText = !disabled ? (
    <span style={{ wordBreak: 'keep-all' }}>
      <span className={classes.squareBracket}> [</span><span className={classes.editText} onClick={handleOpen}>Edit</span><span className={classes.squareBracket}>]</span>
    </span>
  ) : null;

  return (
    <>
      <Grid container className={classes.margin}>
        <Grid container alignItems="center">
          <Grid item className={classes.mainHeader}>
            <Typography variant="h5">MY INFO</Typography>
          </Grid>
        </Grid>
        <Grid container className={classes.body} justify="center" alignItems="center">
          <Grid item xs={5}>
            <Typography variant="h6" className={classes.bodyText}>Full Name:</Typography>
          </Grid>
          <Grid item xs={7}>
            <Typography variant="h6" className={classes.bodyText}>{userData.firstName + ' ' + userData.lastName}{editText}</Typography>
          </Grid>
          <Grid item xs={5}>
            <Typography variant="h6" className={classes.bodyText}>Email Address:</Typography>
          </Grid>
          <Grid item xs={7}>
            <Typography variant="h6" className={classes.emailText}>{userData.email ?? ''}{editText}</Typography>
          </Grid>
          <Grid item xs={5}>
            <Typography variant="h6" className={classes.bodyText}>Mobile Number:</Typography>
          </Grid>
          <Grid item xs={7}>
            <Typography variant="h6" className={classes.bodyText}>{userData.phoneNumber ?? ''}{editText}</Typography>
          </Grid>
          <Grid item xs={5}>
            <Typography variant="h6" className={classes.bodyText}>Pickup/Delivery Address:</Typography>
          </Grid>
          <Grid item xs={7}>
            <Typography variant="h6" className={classes.bodyText}>{formattedAddress}{editText}</Typography>
          </Grid>
          <Grid item xs={5}>
            <Typography variant="h6" className={classes.bodyText}>My Password:</Typography>
          </Grid>
          <Grid item xs={7}>
            <Typography variant="h6" className={classes.bodyText}>***************{editText}</Typography>
          </Grid>
        </Grid>
      </Grid>
      {!disabled && (
        <StripeProvider apiKey={stripeKey}>
          <Elements>
            <EditMyInfoModal modalOpen={modalOpen} setModalOpen={setModalOpen} handleClose={handleClose} userData={userData}/>
          </Elements>
        </StripeProvider>
      )}
    </>
  );
}

export default withStyles(MyInfoCardStyle)(MyInfoCard);
