import {breakpoints, cardShadow, MuiTheme} from 'assets/theme';

const StorageCardStyle = theme => ({
  card: {
    width: '350px',
    height: '400px',
    margin: '0 60px 60px 0',
    backgroundColor: MuiTheme.palette.background.default,
    cursor: 'pointer',
    position: 'relative',
    ...cardShadow
  },
  highlightedCard: {
    width: '350px',
    height: '400px',
    margin: '0 60px 60px 0',
    backgroundColor: MuiTheme.palette.secondary.main,
    cursor: 'pointer',
    position: 'relative',
    ...cardShadow,

    '&:after': {
      content: "''",
      position: 'absolute',
      top: 0,
      left: 0,
      backgroundColor: 'rgba(0,0,0,0)',
      width: '100%',
      height: '100%',
      boxShadow: `inset 0 0 0 3px rgb(255, 224, 1)`,
    }
  },
  cardHeader: {
    color: MuiTheme.palette.secondary.main,
    backgroundColor: MuiTheme.palette.common.black,
    height: '45px',
    maxWidth: '70%',
    display: 'inline-flex',
    justifyContent: 'flex-start',
    alignItems: 'center',
    padding: '0 15px'
  },
  checkbox: {
    position: 'absolute',
    top: '15px',
    right: '15px',
  },
  cardContent: {
    padding: '40px 0 0 0',
    boxSizing: 'border-box',
    position: 'relative',
    zIndex: 10,
    "&:last-child": {
      paddingBottom: 0
    },
    height: '100%',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-between',
    alignItems: 'flex-start'
  },
  cardImage: {
    position: 'absolute',
    zIndex: 0,
    top: '0',
    left: '0',
    width: '100%',
    height: '100%',

    '& img': {
      height: '100%',
      width: '100%'
    }
  },
  textYellow: {
    color: MuiTheme.palette.secondary.main,

    '& strong': {
      color: MuiTheme.palette.secondary.main,
    }
  },
  cardFooter: {
    color: MuiTheme.palette.secondary.main,
    backgroundColor: MuiTheme.palette.common.black,
    height: '70px',
    paddingLeft: '10px',
    paddingTop: '5px'
  },
  highlightedCardFooter: {
    color: MuiTheme.palette.secondary.main,
    backgroundColor: MuiTheme.palette.common.black,
    height: '70px',
    paddingLeft: '10px',
    paddingTop: '5px',

    '&:after': {
      content: "''",
      position: 'absolute',
      top: 0,
      left: 0,
      backgroundColor: 'rgba(0,0,0,0)',
      width: '100%',
      height: '100%',
      boxShadow: `inset 0 0 0 3px rgb(255, 224, 1)`,
    }
  },
  modal: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  paper: {
    backgroundColor: theme.palette.background.paper,
    border: '2px solid #000',
    boxShadow: theme.shadows[5],
    padding: '12px',
  },
  zoomedImage: {
    maxWidth: '80vw',
    maxHeight: '80vh',
  },

  [breakpoints.small]: {
    card: {
      margin: '30px 0',
    },
    highlightedCard: {
      margin: '30px 0',
    }
  }
});

export default StorageCardStyle
